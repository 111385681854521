import axios from "axios";

const instance = axios.create({
	baseURL: "https://osama.in.net/api",
});

export const osamaAPI = {
	getCities() {
		return instance
			.get(`/cities`)
			.then((response) => {
				const data = response.data;
				console.log("data **** ", data);
				return data;
			})
			.catch((err) => console.log("err", err));
	},
};
