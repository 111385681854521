import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    root: props => ({
        color: props.color
    })
  });

export default function Loading(props){
    const classes = useStyles({color: props.color});
    return(
        <CircularProgress size={props.size} className={classes.root}/>
    )
};