import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { addClientRows } from '../../../reducers/clientTable';
import Select from './SelectPageView';
import Pagination from './Pagination';

const pageViewOptions = [
    {id: 25, name: '25 на странице'},
    {id: 50, name: '50 на странице'},
    {id: 100, name: '100 на странице'},
];

const Footer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const allClients = useSelector(state => state.clientTable.allClients);
    const [pageView, setPageView] = useState(25);
    const [pagination, setPagination] = useState(1);
     const [disabledArrow, setDisabledArrow] = useState(false);

    const handlePageView = (e) => {
        console.log(' --- handlePageView --- ');
        const count = e.target.value;
        let rowsData = [...allClients];
        rowsData.length = count;
        
        setPageView(count);
        setPagination(1);

        if (rowsData.length > allClients.length) {
            setDisabledArrow(true);
        } else {
            setDisabledArrow(false);
        }

        dispatch(addClientRows(rowsData));
    }

    return (
        <div className={classes.root}>
            <Select 
                name="pageView"
                className="MuiSelect-select"
                // label="Действия"
                options={pageViewOptions}
                stateValue={pageView} 
                handleChange={handlePageView} 
            />
            <Pagination
                pagination={pagination}
                setPagination={setPagination}
                pageView={pageView} 
                setPreload={props.setPreload}
                getNextClients={props.getNextClients}
                getNextSearch={props.getNextSearch}
                disabledArrow={disabledArrow}
                setDisabledArrow={setDisabledArrow}
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
       display: 'flex',
       justifyContent: 'space-between',
       marginBottom: 0,
       marginTop: 30,
       '& > .MuiSelect-select.MuiSelect-select > div > div': {
           borderRight: '1px solid #326dff',
           borderTopRightRadius: '5px',
           borderBottomRightRadius: '5px',
           borderColor: theme.palette.primary.main,
           color: theme.palette.primary.main,
       },
       '& > .MuiSelect-select.MuiSelect-select > div > svg': {
           color: theme.palette.primary.main
       }
    },
}));

export default Footer;
