import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../firebase';
import { osamaAPI } from '../../../api/osamaApi';
import { withActions } from '../../../api'

import { 
    addShops, 
    updateAdminSettings, 
    updateLanguages, 
    updateDefaultLanguage, 
    updateCategoriesDispatch, 
    updateProductsDispatch, 
    updateKeyShopDispatch,
    addAdminPermission,
    updateCategoriesOrder,
    updateCategoriesOrderDispatch
} from '../../../reducers/shops';

import DefaultScreen from './defaultScreen';

const DefaultScreenContainer = (props) => {
    const dispatch = useDispatch();
    const city = useSelector(state => state.shops.shops);
    const keyShop = useSelector(state => state.shops.keyShop);
    const categories = useSelector(state => state.shops.categories);
    const products = useSelector(state => state.shops.products);
    
    const [open, setOpen] = useState(false);
    const [isDirtyWorkingHours, setIsDirtyWorkingHours] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [listCity, setListCity] = useState([]);
    const [defaultHiddenCategories, setDefaultHiddenCategories] = useState(null);
    const [defaultHiddenProducts, setDefaultHiddenProducts] = useState(null);
    const [defaultCity, setDefaultCity] = useState(null);
    const [loadCity, setLoadCity] = useState(true);
    const [openCategory, setOpenCategory] = useState(null);
    const [openProducts, setOpenProducts] = useState(null);
    const [category, setCategory] = useState(null);
    const [adminPermission, setAdminPermission] = useState(null);
    const [adminSettings, setAdminSettings] = useState(null);
    const [categoriesMenu, setCategoriesMenu] = useState(false);
    const [categoriesPromocode, setCategoriesPromocode] = useState(false);
    const [productsMenu, setProductsMenu] = useState(false);
    const [productsPromocode, setProductsPromocode] = useState(false);
    const [preloader, setPreloader] = useState(false);

    // const isDebug = JSON.parse(localStorage.getItem('isDebug'))
    const isDebug = props.isDebug

    useEffect(() => {
        getCities();
        getDefaultCity();
        getAdminPermission();
        getAdminSettings();
        getDefaultHiddenCategories();
        getDefaultHiddenProducts();
        // getCategories();
        getProductsState();
    }, [isDebug]);

    const getProductsState = () => {
        let isProducts = props.location.pathname.match('/page/menu/products');
  
        if (isProducts) {
          getDefaultLanguage();
          getLanguages();
        //   getAdminPermission();
          getCategories();
          getProducts();
        }
    }

    const getProducts = async () => {
        const products = await props.getProducts()
        if (products) updateProductsDispatch(dispatch, products);
       
        // props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/products`).once("value", (snapshot) => {
        //     let products = snapshot.val();
        //     console.log('products from firebase ***', products)
        //     if (products) updateProductsDispatch(dispatch, products);
        // });
    }

    const getCategories = async () => {
        const categories = await props.getCategories();
        const cities = await props.getCities();
        const spots = cities.map(item => ({spot_id: item.data.deliveryTerminals[0].id, visible: '1'}))
        
        for (let item of categories) {
    
            const hiddenSpots = await props.getHiddenSpotsCategory(item.id)
            
            const itemHiddenSpots = hiddenSpots.map(item => ({id: item.id, spot_id: item.spot_id, visible: '0'}))
            const itemVisibleSpots = spots.filter(item => !itemHiddenSpots.some(it => +item.spot_id === +it.spot_id))

            item.spots = itemVisibleSpots
            item.hiddenOnSpots = itemHiddenSpots
        }
        const sortedCategories = categories.sort((a,b) => a.order - b.order)
        updateCategoriesDispatch(dispatch, sortedCategories);

        // props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/categories`).once("value", (snapshot) => {
        //     let categories = snapshot.val();
        //     console.log('categories',categories )
        //     const categoriesIds = categories ? Object.keys(categories) : [];
          
        //     if (categories) {
        //         props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/Category/categoriesOrder`).once("value", (snapshot) => {
        //         let categoriesOrder = snapshot.val();
        //         let data = {};
        //         console.log('categoriesOrder',categoriesOrder )
                
        //         if (categoriesOrder) {
        //             updateCategoriesOrderDispatch(dispatch, categoriesOrder);
        //             let categoriesOrders = [];
        //             const categoriesOrderIds = Object.values(categoriesOrder);

        //             categoriesIds.map(id => {
        //                 categoriesOrderIds.map(orderId => {
        //                     if (orderId === id) {
        //                     categoriesOrders.push(id);
        //                     }
        //                 });
        //             });

        //             for (let key in categoriesOrders) {
        //                 if (categories[categoriesOrder[key]]) {
        //                     data[categoriesOrder[key]] = categories[categoriesOrder[key]]
        //                 }
        //             }
        //         }

        //         if (Object.keys(data).length > 0) updateCategoriesDispatch(dispatch, data);
        //         if (Object.keys(data).length === 0 && categories) updateCategoriesDispatch(dispatch, categories);
        //         });
        //     }
        // });
    }

    const getCities = async () => {
        setLoadCity(true);

        const cities = await props.getCities();
        const settings = await props.getSettings()

        const listCity = cities.map(item => ({...item.data, cityId: item.id}))

        const collator = new Intl.Collator('uk');

        
        // props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings`).once('value', (snapshot) => {
        //     let items = snapshot.val();
            // let listCity = [];
            // let city = items.City;

            // for (let item in city) {
            //     listCity.push({
            //     ...city[item],
            //     key: item
            //     })
            // }
            if (settings) {

            listCity.sort((a, b) => collator.compare(a.spot_name, b.spot_name))
            

            // let data = {shops: listCity, appSettings: items.appSettings, category: items.Category};
            let data = {shops: listCity, appSettings: settings.appSettings, category: settings.category};


            addShops(dispatch, data);
            setLoadCity(false);
            setListCity(listCity);
            }
        // });
    };

    const getDefaultCity = () => {
        let cityId = window.location.pathname.split('/');
        cityId = cityId[cityId.length - 1];

        setLoadCity(true);
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/City/${cityId}`).on('value', (snapshot) => {
        let defaultCity = snapshot.val();
        
        // console.log('defaultCity', defaultCity);
        
        setDefaultCity(defaultCity);
        setLoadCity(false);
        })
    };

    const getAdminPermission = () => {
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/adminPermission`).once("value", (snapshot) => {
        let adminPermission = snapshot.val();
        setAdminPermission(adminPermission);
        addAdminPermission(dispatch, adminPermission);
        });
    }

    const getAdminSettings = () => {
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/adminSettings`).once("value", (snapshot) => {
          let val = snapshot.val();

          if (!adminSettings) {
            updateAdminSettings(dispatch, val);
            setAdminSettings(val);
          };
        });
    }

    const getDefaultHiddenCategories = () => {
        setLoadCity(true);
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/Category/hiddenCategories`).once('value', (snapshot) => {
            let val = snapshot.val();

            setDefaultHiddenCategories(val);
            setLoadCity(false);
        });
    };

    const getDefaultHiddenProducts = () => {
        setLoadCity(true);
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/Category/hiddenProducts`).once('value', (snapshot) => {
            let val = snapshot.val();

            setDefaultHiddenProducts(val);
            setLoadCity(false);
        });
    };

    const getDefaultLanguage = () => {
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/adminPermission/defaultLanguage`).once("value", (snapshot) => {
            let defaultLanguage = snapshot.val();

            if (defaultLanguage) updateDefaultLanguage(dispatch, defaultLanguage);
        });
    }

    const getLanguages = () => {
        props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/languages`).once("value", (snapshot) => {
            let languages = snapshot.val();

            if (languages) updateLanguages(dispatch, languages);
        });
    }

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);
    const handleCategoriesMenu = (value) => setCategoriesMenu(value);
    const handleProductsMenu = (value) => setProductsMenu(value);
    const handleCategoriesPromocode = (value) => setCategoriesPromocode(value);
    const handleProductsPromocode = (value) => setProductsPromocode(value);
    const handleIsDirtyWorkingHours = (value) => setIsDirtyWorkingHours(value);

    // const openSettings = () => {
    //     if(!openSettings && listCity.length === 0) getCities();
        
    //     setOpenSettings(openSettings ? false : true);
    //     setCity(city);
    // }

    const chooseCity = (key) => {
        updateKeyShopDispatch(dispatch, keyShop === key ? null : key);
    }

    const handleOpen = (category) => {
        setOpenCategory(category === openCategory ? null : category);      
    }

    const handleProductOpen = (category) => {
        if (openProducts !== category && products.length <= 0 && categories.length <= 0) {
          setPreloader(true);
          getCategories();
          getProducts();
          getLanguages();
          getDefaultLanguage();
          setTimeout(() => setPreloader(false), 200);
        }

        setOpenProducts(category === openProducts ? null : category);
    }

    return (
       <DefaultScreen 
            open={open}
            preloader={preloader}
            handleDrawerClose={handleDrawerClose}
            openSettings={openSettings}
            chooseCity={chooseCity}
            keyShop={keyShop}
            loadCity={loadCity}
            city={city}
            defaultCity={defaultCity}
            defaultHiddenCategories={defaultHiddenCategories}
            defaultHiddenProducts={defaultHiddenProducts}
            getDefaultHiddenCategories={getDefaultHiddenCategories}
            getDefaultHiddenProducts={getDefaultHiddenProducts}
            openCategory={openCategory}
            openProducts={openProducts}
            handleOpen={handleOpen}
            handleProductOpen={handleProductOpen}
            adminPermission={adminPermission}
            adminSettings={adminSettings}
            categoriesMenu={categoriesMenu}
            productsMenu={productsMenu}
            categoriesPromocode={categoriesPromocode}
            productsPromocode={productsPromocode}
            isDirtyWorkingHours={isDirtyWorkingHours}
            handleIsDirtyWorkingHours={handleIsDirtyWorkingHours}
            handleDrawerOpen={handleDrawerOpen}
            handleCategoriesMenu={handleCategoriesMenu}
            handleProductsMenu={handleProductsMenu}
            getDefaultCity={getDefaultCity}
            handleCategoriesPromocode={handleCategoriesPromocode}
            handleProductsPromocode={handleProductsPromocode}
       />
    )
}

export default withActions(withFirebase(withRouter(DefaultScreenContainer)));
