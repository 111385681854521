import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const Header = ({ count }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography className={classes.typography} variant="h6" component="h2">Все клиенты ({count})</Typography>
            <hr className={classes.line} />
        </div>
    )
}

const useStyles = makeStyles({
    root: {
       marginBottom: 16,
    },
    typography: {
        // marginBottom: 16,
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '29px',
        color: '#333',
        marginBottom: 5,
    },
    line: {
        display: 'block',
        margin: 0,
        padding: 0,
        border: 0,
        height: 1,
        width: '100%',
        background: '#ebebeb'
    }
});

export default Header;
