import React from 'react';
import s from './login.module.css';


const LoginSceen = (props) => {
    return(
        <div className={s.container}>
            <div className={s.containerForm}>
                <div className={s.row}>
                    <div className={s.wrapper}>
                        <div className={s.bgContainer}/>
                    </div>
                    <div className={s.wrapper}>
                        <div className={s.loginForm}>
                                {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginSceen;