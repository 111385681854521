import React, { Component } from "react";

import Reviews from "./Reviews";
import { withFirebase } from "../../firebase";
import moment from "moment";

class ReviewsContainer extends Component {
	constructor() {
		super();

		this.state = {
			key: null,
			startKey: null,
			review: [],
			orders: [],
			items: [],
			typeSort: "newest",
			loadStatus: true,
			loadButton: false,
			endKey: null,
			showMore: false,
		};
	}

	componentDidMount() {
		this.getProductReviews();
		this.getOrderReviews();
	}

	componentDidUpdate(prevProps, nextState) {
		if (this.state.typeSort !== nextState.typeSort) {
			let sortedList;

			if (nextState.typeSort === "lowGrade") {
				sortedList = this.state.review.sort(function (a, b) {
					return b.timestamp - a.timestamp;
				});
			}

			if (nextState.typeSort === "newest") {
				sortedList = this.state.review.sort(function (a, b) {
					return b.rating - a.rating;
				});
			}

			if (nextState.typeSort === "highGrade") {
				sortedList = this.state.review.sort(function (a, b) {
					return a.rating - b.rating;
				});
			}

			this.setState({
				review: sortedList,
			});
		}
	}

	getOrderReviews = () => {
		let ref = this.props.firebase.bd.ref(
			`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/review`
		);
		ref
			.orderByKey() /*.startAt('17878')*/
			.limitToLast(7)
			.once("value", (snapshot) => {
				let items = snapshot.val();
				if (items) {
					let keys = Object.keys(items);
					let key = keys[this.state.key];
					let list = [];

					for (let item in items) {
						let rew = items[item];
						let date = item;
						for (let i in rew) {
							list.push({
								...rew[i],
								phone: i,
								date: moment(
									new Date(Number(date) * 1000 * 60 * 60 * 24)
								).format("YYYY-MM-DD"),
							});
						}
					}

					let sortedDateList = list.sort(function (a, b) {
						return b.timestamp - a.timestamp;
					});

					this.setState({
						orders: sortedDateList,
						startKey: key,
						loadStatus: false,
						showMore: keys.length === 7 ? true : false,
					});
				}
			});
	};

	getProductReviews = () => {
		let ref = this.props.firebase.bd.ref(
			`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/reviews_fb`
		);
		ref
			.orderByKey() /*.startAt('17878')*/
			.limitToLast(7)
			.once("value", (snapshot) => {
				let items = snapshot.val();

				this.setState({ items });

				if (items) {
					let keys = Object.keys(items);
					let key = keys[this.state.key];
					// items = items[key] && items[key].reviews;

					let list = [];

					for (let item in items) {
						let rew = items[item];
						for (let item in rew) {
							for (let categorieName in rew[item]) {
								list.push({
									...rew[item][categorieName],
									categorieName,
								});
							}
						}
					}

					let sortedDateList = list.sort(function (a, b) {
						return b.timestamp - a.timestamp;
					});

					this.setState({
						review: sortedDateList,
						startKey: key,
						loadStatus: false,
						showMore: keys.length === 7 ? true : false,
					});
				} else {
					this.setState({
						loadStatus: false,
					});
				}
			});
	};

	getMoreReview = () => {
		this.setState({ loadButton: true });
		let ref = this.props.firebase.bd.ref(
			`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/review`
		);
		ref
			.orderByKey()
			.endAt(this.state.startKey)
			.limitToLast(8)
			.on("value", (snapshot) => {
				let items = snapshot.val();
				console.log("items", items);
				console.log("startKey", this.state.startKey);
				delete items[this.state.startKey];
				let key = Object.keys(items);
				//items = items[key[0]];
				let list = [];

				for (let item in items) {
					let rew = items[item];
					let date = item;
					for (let item in rew) {
						list.push({
							CallCenter: rew[item].CallCenter,
							Courier: rew[item].Courier,
							NPS: rew[item].NPS,
							Suggestions: rew[item].Suggestions,
							SushiQuality: rew[item].SushiQuality,
							phone: item,
							date: moment(new Date(Number(date) * 1000 * 60 * 60 * 24)).format(
								"YYYY-MM-DD"
							),
						});
					}
				}
				this.setState({
					orders: [...this.state.orders, ...list],
					startKey: key[0],
					loadButton: false,
					showMore: key.length === 7 ? true : false,
				});
			});
	};

	sort = (type) => {
		this.setState({
			typeSort: type,
		});
	};

	handleKey = (key) => {
		this.setState({ key });
	};

	deleteReview = (id) => {
		let filtredReview = this.state.review.filter(
			(item) => item.categorieName !== id
		);
		let items = this.state.items;
		let key;

		for (let item in items) {
			for (let itemKey in items[item].reviews) {
				if (id === itemKey) {
					key = item;
				}
			}
		}

		this.setState({
			review: filtredReview,
		});

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/reviews_fb/${key}/reviews`
			)
			.child(id)
			.remove()
			.then((res) => {
				this.setState({ preloader: false });
			})
			.catch((err) => {
				console.error(err);
			});
	};

	render() {
		return (
			<Reviews
				review={this.state.review}
				isActiveReviews={this.props.reviews}
				isActiveOrderReviews={this.props.orders}
				isActiveApps={this.props.apps}
				typeSort={this.state.typeSort}
				sort={this.sort}
				orders={this.state.orders}
				loadStatus={this.state.loadStatus}
				loadButton={this.state.loadButton}
				getMoreReview={this.getMoreReview}
				showMore={this.state.showMore}
				handleKey={this.handleKey}
				deleteReview={this.deleteReview}
			/>
		);
	}
}

export default withFirebase(ReviewsContainer);
