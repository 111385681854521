import React from 'react';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SelectComponent = ({ name, stateValue, handleChange, options, children, label }) => {
    const classes = useStyles();
    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId={`${name}-label`}
                displayEmpty
                classes={{ 
                    root: classes.root,
                    icon: classes.icon,
                    inputAdornedStart: classes.inputAdornedStart,
                }}
                id={name}
                value={stateValue}
                onChange={handleChange}
            >
                <MenuItem style={{ display: 'none' }} value={0}><div className={classes.firstInput}>{label}</div></MenuItem>
                {options && options.map(({id, name}) => (
                    <MenuItem value={id}><div className={classes.firstInput}>{name}</div></MenuItem>
                ))}
                {children}
            </Select>
        </FormControl>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.primary.main,
        borderRadius: 5,
        lineHeight: "45px",
        color: 'white',
        textAlign: 'center',
        boxSizing: 'border-box',
        '&.MuiSelect-select.MuiSelect-select': {
            padding: '0px 36px 0px 16px',
        },
    },
    icon: {
        color: 'white',
        right: '10px',
    },
     formControl: {
        background: theme.palette.primary.main,
        borderRadius: 5,
         '& > div:after': {
             content: 'none',
         },
         '& > div:before': {
             content: 'none',
         },
     }
}));

export default SelectComponent;