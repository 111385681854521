export const getInitialValues = (props) => {
    const { saleFirstOrder: {parametr, type}, count } = props;

    let initialValues = {};

    if (count === 1 && type === 1) {
        initialValues['present'] = parametr
    }

    if (count === 2 && type === 2) {
        initialValues['discount'] = parametr
    }

    if (count === 3 && type === 3) {
        initialValues['amount'] = parametr
    }

    return { initialValues };

}