import React, { Component, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import DisableApp from "./disableApp";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	updateKeyShop,
	editShops,
	updateSettings,
	updateShops,
} from "../../reducers/shops";
import { withFirebase } from "../../firebase";
import { withActions } from "../../api";

class DisableAppContainer extends Component {
	constructor() {
		super();

		this.state = {
			checked: true,
			keyShop: null,
			orderAvailable: null,
		};
	}

	componentDidMount() {
		// this.props.updateKeyShop(this.props.match.params.city);
		this.setState({
			keyShop: this.props.match.params.city,
		});
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	console.log(
	// 		"prevProps.shops.orderAvailable **** ",
	// 		prevProps.shops.orderAvailable
	// 	);
	// 	console.log(
	// 		"this.props.shops.orderAvailable ****",
	// 		this.props.shops.orderAvailable
	// 	);
	// 	if (prevProps.shops.orderAvailable !== this.props.shops.orderAvailable) {
	// 		this.updateStatusApp();
	// 		console.log("did update");
	// 	}
	// }
	// const DisableAppContainer = (props) => {
	// 	const [checked, setChecked] = useState(true);
	// 	const [keyShop, setKeyShop] = useState(null);
	// 	useEffect(() => {
	// 		props.updateKeyShop(props.match.params.city);
	// 		setKeyShop(props.match.params.city);
	// 	}, []);

	// 	useEffect(() => {
	// 		updateStatusApp();
	// 	}, [props.shops.orderAvailable]);
	// 	console.log("props", props);

	// 	const updateStatusApp = () => {
	// 		console.log("props.shops[props.match.params.city].orderAvailable", props);
	// 		let value = props.shops[props.match.params.city].orderAvailable
	// 			? false
	// 			: true;
	// 		const isDebug = props.isDebug;
	// 		props.editShops({
	// 			key: props.match.params.city,
	// 			params: "orderAvailable",
	// 			value,
	// 		});
	// 		props.firebase.bd
	// 			.ref(
	// 				`${isDebug ? "debug" : ""}/settings/City/${
	// 					this.props.match.params.city
	// 				}/`
	// 			)
	// 			.update({ orderAvailable: value });
	// 		if (localStorage.getItem("@login") === "osama-sushi") {
	// 			props.firebase.bd
	// 				.ref(`${isDebug ? "debug" : ""}/updates`)
	// 				.update({ lastUpdateInMenu: Date.now() });
	// 		}
	// 	};

	// 	const updateAlert = (data) => {
	// 		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
	// 		if (data.text !== props.appSettings.closedSpotAlertDescription) {
	// 			props.updateSettings({
	// 				params: "closedSpotAlertDescription",
	// 				value: data.text,
	// 			});
	// 			props.firebase.bd
	// 				.ref(`${isDebug ? "debug" : ""}/settings/appSettings/`)
	// 				.update({ closedSpotAlertDescription: data.text });
	// 		}
	// 	};

	updateStatusApp = async (newValue) => {
		// let value = this.props.shops[this.props.match.params.city].orderAvailable
		// 	? false
		// 	: true;

		const shop = this.props.shops.find(
			(item) => item.spot_id === this.props.match.params.city
		);

		const res = await this.props.updateCity({
			...shop,
			orderAvailable: newValue,
		});

		if (!!res) {
			// this.props.editShops({
			// 	key: this.props.match.params.city,
			// 	params: "orderAvailable",
			// 	value: newValue,
			// });

			const updatedShops = this.props.shops.map((item) =>
				item.spot_id === shop.spot_id
					? { ...shop, orderAvailable: newValue }
					: item
			);

			this.props.updateShops(updatedShops);
			alert("Готово");
		}

		// this.setState({
		// 	orderAvailable: !newValue,
		// });
		// this.props.firebase.bd
		// 	.ref(
		// 		`${isDebug ? "debug" : ""}/settings/City/${
		// 			this.props.match.params.city
		// 		}/`
		// 	)
		// 	.update({ orderAvailable: value });
		// if (localStorage.getItem("@login") === "osama-sushi") {
		// 	this.props.firebase.bd
		// 		.ref(`${isDebug ? "debug" : ""}/updates`)
		// 		.update({ lastUpdateInMenu: Date.now() });
		// }
	};

	// updateShops = (data) => {
	// 	this.props.editShops(data);
	// };

	updateAlert = async (data) => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (data.text !== this.props.appSettings.closedSpotAlertDescription) {
			this.props.updateSettings({
				params: "closedSpotAlertDescription",
				value: data.text,
			});

			const res = await this.props.updateAppSettings({
				...this.props.appSettings,
				closedSpotAlertDescription: data.text,
			});

			res && alert("Уведомление сохранено");
			// this.props.firebase.bd
			// 	.ref(`${isDebug ? "debug" : ""}/settings/appSettings/`)
			// 	.update({ closedSpotAlertDescription: data.text });
		}
	};

	render() {
		return (
			<>
				<DisableApp
					updateStatusApp={this.updateStatusApp}
					// updateShops={this.props.updateShops}
					keyShop={this.props.match.params.city}
					closedAlert={this.props.closedAlert}
					shops={this.props.shops.filter(
						(item) => item.spot_id === this.props.match.params.city
					)}
					// shops={this.props.shops}
					alert={this.props.appSettings.closedSpotAlertDescription}
					orderAvailable={this.orderAvailable}
					editShops={this.props.editShops}
					// editShops={editShops}
					updateAlert={this.updateAlert}
					isDebug={this.props.isDebug}
					updateCity={this.props.updateCity}
				/>
				{/* <DisableApp
				updateStatusApp={updateStatusApp}
				keyShop={props.match.params.city}
				closedAlert={props.closedAlert}
				shops={props.shops[props.match.params.city]}
				alert={props.appSettings.closedSpotAlertDescription}
				editShops={editShops}
				updateAlert={updateAlert}
			/> */}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, {
	updateKeyShop,
	editShops,
	updateSettings,
	updateShops,
})(withActions(withRouter(withFirebase(DisableAppContainer))));
