import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import ImageUploading from 'react-images-uploading';
import Typography from '@material-ui/core/Typography';
import Button from '../../../components/Button';
import ButtonMaterial from '@material-ui/core/Button';
import Switch from '../../../components/Switch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ModalComponent from '../../../components/ModalComponent';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ImgsViewer from 'react-images-viewer'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import dndIcon from './assets/dnd.svg';
import useStyles from './styles';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Banners = (props) => {
    const classes = useStyles();
    // const referenceForBanner = useSelector(state => state.shops.adminPermission.referenceForBanner);

    const [openModal, setOpenModal] = useState(false);
    const [openDataModal, setOpenDataModal] = useState(false);
    const [openMessageModal, setOpenMessageModal] = useState(false);

    const [isDataSubmit, setIsDataSubmit] = useState(false);
    const [isDeleteSubmit, setIsDeleteSubmit] = useState(false);
    const [IsMessageSubmit, setIsMessageSubmit] = useState(false);

    const [deleteId, setDeleteId] = useState(false);
    const [deleteBanners, setDeleteBanners] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [viewPhotos, setViewPhotos] = useState([]);
    const [currentPhoto, setCurrentPhoto] = useState(0);

    const [value, setValue] = useState({});
    const [textIndex, setTextIndex] = useState(null);
    const [activeInput, setActiveInput] = useState({});

    const [open, setOpen] = useState(true);

    const handleSnackBarClose = () => {
      setOpen(false);
      setTimeout(() => setOpen(true), 6100);
    };

    const handleInputChange = (e, index) => {
        const val = e.target.value;

        setValue({
            ...value,
            [index]: val
        });
    }

    const handleSetActiveInput = (index) => {
        setActiveInput({
            ...activeInput,
            [index]: !activeInput[index]
        });
    }

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            setTextIndex(index);
            setOpenMessageModal(true);
        }
    }

    const onSubmitSaveText = (index) => {
        setTextIndex(index);
        setOpenMessageModal(true);
    }

    const {
        images, 
        setImages, 
        banners, 
        allBanners, 
        getImages,
        setBanners,
        onSubmit,
        spotId,
    } = props;

    const imageViewClick = (num) => {
        setCurrentPhoto(num);
        setIsOpen(true);
    }

    const handleClose = () => {
        setOpenModal(false);
        setIsDeleteSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsDeleteSubmit(true);
    };

    const handleMessageClose = () => {
        setOpenMessageModal(false);
    };

    const handleDataClose = () => {
        setOpenDataModal(false);
        setIsDataSubmit(false);
    };

    const handleClickDataSubmit = () => {
        setOpenDataModal(false);
        setIsDataSubmit(true);
    };

    const handleClickMessageSubmit = () => {
        setOpenMessageModal(false);
        setIsMessageSubmit(true);
    };

    // DND
    const onDragEnd = (result) => {
        console.log( '--- dnd ---' );
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const data = reorder(
          images,
          result.source.index,
          result.destination.index,
        );

        let newData = [];
        let imgs = [];
        
        for (let key in data) {
            let item = {
                ...data[key],
                dnd: true,
                order: String(+key + 1),
            };

            imgs.push({ src: item.photo })
            newData.push(item);
        }

        setViewPhotos(imgs);
        setImages([]);
        setBanners(newData);
    }

    const maxNumber = 999;
    const maxFileSize = 512000;
    const maxAcceptType = ['jpg', 'gif', 'png'];
 
    const onChange = (imageList, addUpdateIndex) => {
        console.log('onChange');

        let data = [...images];
        let item = { ...imageList[0], upload: true, available: true };

        data.push(item);
        setImages(data);
    };

    const changeChecker = (val, id) => {
        console.log('changeChecker');
        let data = [...images];
        let item = images[id];
        let available = item && item.available ? !item.available : val;

        data[id] = { ...data[id], available, update: true };

        setImages(data);
    }

    const deleteBanner = (id) => {
        console.log('deleteBanner ---', id);
        let deleteBanners = [];
        let filtredBanners = images.filter(image => image.order != (id + 1));

        filtredBanners.forEach(({ available, order, photo, spotId }, index) => {
            deleteBanners.push({
                available,
                order,
                photo,
                spotId,
                order: String(index + 1)
            });
        });

        // console.log('deleteBanners', deleteBanners);

        setOpenModal(true);
        setDeleteBanners(deleteBanners);
        setDeleteId(id);

        // setTimeout(() => setImages([]), 100);
    }

    const saveClick = () => {
        let data = [];
        let uploadImages = images.filter(image => image.upload === true);
        let dndImages = images.filter(image => image.dnd === true);

        if (uploadImages.length > 0) {
            for (let key in uploadImages) {
                data.push({
                    ...uploadImages[key],
                    order: uploadImages.length > 1 ? String(+key + 1) : String(images.length),
                    available: images[key].available !== undefined ? images[key].available : true,
                    spotId
                });
            }
        }

        if (dndImages.length > 0) {
            for (let key in dndImages) {
                data.push({
                    ...dndImages[key],
                    available: !!dndImages[key].available ? dndImages[key].available : true,
                    spotId
                });
            }
        }

        if (uploadImages.length === 0 && dndImages.length === 0 && value) {
            images.forEach((banner, index) => {
                data[index] = { ...banner };
            })
        }

        console.log('data', data);

        onSubmit(data);
        getImages();
        setImages(images);

    };

    useEffect(() => {
        // console.log('isDataSubmit', isDataSubmit);
        if (isDeleteSubmit) {
            let deleteImg = images.find(((item, index) => index === deleteId));

            if (deleteImg && deleteImg.photo) {
                let photoDeleteImg = deleteImg.photo.split('/');
                photoDeleteImg = photoDeleteImg[photoDeleteImg.length - 1];
    
                let data = {
                    id: deleteId,
                    data: deleteBanners,
                    name: photoDeleteImg
                }

                // console.log('deleteBanners', deleteBanners);
    
                // setImages(deleteBanners);
                props.deleteBanner(data);
                setIsDeleteSubmit(false);
            } else {
                console.log('--- no Save ---', deleteBanners);
                setImages(deleteBanners);
                setIsDeleteSubmit(false);
            }
        };

        if (isDataSubmit) {
            saveClick();
            setIsDataSubmit(false);
        };

        // if (IsMessageSubmit) {
        //     props.saveText(value[textIndex], textIndex);
        //     setIsMessageSubmit(false);
        // }
    }, [isDeleteSubmit, isDataSubmit, IsMessageSubmit, deleteBanners, deleteId]);

    useEffect(() => {
        const filtredBanners = banners.filter(banner => banner.spotId === spotId);

        if (images.length === 0 && filtredBanners && filtredBanners.length > 0) {
            let photos = [];
            let values = {};

            filtredBanners.map((item, index) => {
                photos.push({ src: item.photo });
                // values[index] = item.productId ? item.productId : ''
            });

            setImages(filtredBanners);
            setViewPhotos(photos);
            setValue(values);
        }

    }, [banners, images]);

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
      }

    const SnackBarComponent = ({ errorMessage }) => (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackBarClose}>
            <Alert onClose={handleSnackBarClose} severity="error">
                {errorMessage}
            </Alert>
        </Snackbar>
    );

    console.log('props.banners', props.banners);
    // console.log('--- images ---', images);
    // console.log('--- value ---', value);
    // console.log('--- activeInput ---', activeInput);

    return (
        <div className={classes.banners}>
            <ModalComponent
                title="Подтвердите действие!"
                description={`Хотите удалить баннер?`} 
                open={openModal} 
                handleClose={handleClose} 
                handleClickSubmit={handleClickSubmit} />
            <ModalComponent
                title="Подтвердите действие!"
                description={`Вы подтверждаете сохранение баннеров`} 
                open={openDataModal} 
                handleClose={handleDataClose} 
                handleClickSubmit={handleClickDataSubmit} />
            <ModalComponent
                title="Подтвердите действие!"
                description={`Подтвердите сохранение текста`} 
                open={openMessageModal} 
                handleClose={handleMessageClose} 
                handleClickSubmit={handleClickMessageSubmit} />
               <ImgsViewer
                    className={classes.imgsViewer}
                    imgs={viewPhotos}
                    currImg={currentPhoto}
                    leftArroshowCloseBtnwTitle={false}
                    showImgCount={false}
                    preventAutoScroll={false}
                    enableKeyboardInput={false}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                />
            <div className={classes.bannersContent}>
                <Typography variant="h6" component="h2" className={classes.warningMessage}>
                    Рекомендуемый размер фото для баннера 650x900 (расширение файла jpg, jpeg, png)
                </Typography>
                {images.length === 0 && <div className={classes.noBanners}>Пока нет баннеров</div>}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            // style={getListStyle(snapshot.isDraggingOver)}
                            >
                            {images.map((item, index) => {  
                                return (
                                    <Draggable key={index} draggableId={String(index)} index={index}>
                                        {(provided, snapshot) => (
                                            <div 
                                                key={index} 
                                                className={classes.bannerWrap}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                >
                                                <div key={index} className={classes.banner}>
                                                    <div className={classes.bannerContentWrap}>
                                                        <div className={classes.imageWrap}>
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${item.data_url ? item.data_url : item.photo })`
                                                                }} 
                                                                className={classes.image}
                                                                onClick={() => { imageViewClick(index) } }></div>
                                                            {/* <img 
                                                                onClick={() => { imageViewClick(index) } }
                                                                className={classes.image} 
                                                                src={item.data_url ? item.data_url : item.photo} 
                                                                alt={`Баннер - ${index}`} /> */}
                                                        </div>
                                                    </div>
                                                    <div className={classes.controlWrap}>
                                                        {/* <div className={classes.switch}>
                                                            <Switch 
                                                                className={classes.switchComponent} 
                                                                active={item.available}
                                                                changeChecker={changeChecker}
                                                                id={index} />
                                                        </div> */}
                                                        <div className={classes.trash} onClick={() => deleteBanner(index)}>
                                                            <DeleteOutlineIcon className={classes.trashIcon} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.dnd}>
                                                    <img src={dndIcon} alt="dnd" />
                                                </div>
                                            </div>
                                            )}
                                        </Draggable>
                                    )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className={classes.imageUploading}>
                <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    maxFileSize={maxFileSize}
                    acceptType={maxAcceptType}
                    dataURLKey="data_url"
                >
                    {({
                    onImageUpload,
                    isDragging,
                    errors
                    }) => (
                    // write your building UI
                    <div className={classes.buttonsWrap}>
                        <div className={classes.buttons}>
                            <Button
                                className={classes.addBanner}
                                handleClick={onImageUpload} 
                                load={false}
                                text={'Добавить баннер'}
                            />
                            <Button 
                                className={classes.saveBanner} 
                                load={false}
                                text={'Сохранить'}
                                handleClick={() => setOpenDataModal(true)}
                            />
                        </div>
                        {/* { console.log('errors', errors) } */}
                        {errors && (
                            <div>
                                <SnackBarComponent errorMessage={
                                   errors.maxNumber ? 'Выбранное изображение имеет слишком большое название' :
                                   errors.acceptType ? 'Поддерживаемые разширения изображений jpg, jpeg, png' :
                                   errors.maxFileSize ? 'Размер фото не более 0.5 МБ' :
                                   errors.resolution ? 'Максимальное расширение изображения 1920px x 1280px' : ''
                                } />
                            </div>
                        )}
                    </div>
                    )}
                </ImageUploading>
            </div>
        </div>
    )
}

export default Banners;