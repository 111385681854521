import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';

import Tabs from './tabs';

const Discount = (props) => {
    const classes = useStyles();
    const {saleFirstOrder, promoCodes} = props;
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    const Title = ({ title }) => 
        <FormLabel className={classes.formLegend} component="legend">
            <Typography className={classes.title} variant="h3">{title}</Typography>
        </FormLabel>

    const Wrapper = ({ children }) =>
        <Grid container > 
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                {children}
                </Paper>
            </Grid>
        </Grid>

    return (
        <div className={classes.defaultWrapper}>
            {isMin600 && <Typography className={classes.title} variant="h3">Система лояльности</Typography>}
            <Wrapper classes={classes}>
                {isMax600 && <Title className={classes.titleMobile} title="Система лояльности" />}
                <Tabs 
                    saleFirstOrder={saleFirstOrder}
                    promoCodes={promoCodes}
                    discontProps={props}
                    {...props}
                />
            </Wrapper>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    defaultWrapper: {
        [theme.breakpoints.down('599')]: {
            marginTop: 16,
        }
    },
    paper: {
        minHeight: 'calc(100vh - 150px)',
        padding: theme.spacing(2),
        paddingTop: 24,
        paddingBottom: 16,
        [theme.breakpoints.down('600')]: {
            padding: 0,
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
        },
    },
    title: {
        fontFamily: 'Roboto',
        fontSize: 22,
        lineHeight: '26px',
        fontWeight: 'bold',
        letterSpacing: '0.003em',
        color: 'black'
    },
    titleMobile: {
        // marginTop: 12,
        marginBottom: 8,
        fontFamily: 'Roboto',
        fontSize: 22,
        lineHeight: '26px',
        fontWeight: 'bold',
        letterSpacing: '0.003em',
        color: 'black'
    },
    arrowIcon: {
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
      formLegend: {
        display: 'flex',
    },
}))

export default Discount;