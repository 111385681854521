import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    listContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
    },
    item: {
        width: '100%',
        height: 57,
        display: 'flex',
        padding: '0 16px',
        background: 'white',
        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        boxSizing: 'border-box',
        borderRadius: 5,
        marginBottom: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
        '& span': {
            fontSize: 18,
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                maxWidth: 110,
                overflow: 'hidden',
            }
        }
    },
    itemWrap: {
    },
    wrapper: {
        paddingRight: 30,
        display: 'flex',
        position: 'relative',
        top: 3,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        }
    },
    eye: {

    },
    edit: {
        '& span' : {
            marginLeft: 10,
        },
        '& img': {
            width: 'auto',
            height: 20,
            cursor: 'pointer',
        }
    },
    editIcon: {
        cursor: 'pointer',
    },
    editLink: {
        marginLeft: 10,
        display: 'block',
        color: 'black',
        '&:hover': {
            opacity: .75,
        }
    },
    copyCategoryWrapper: {
        position: 'relative',
    },
    copyCategory: {
        display: 'flex',
        position: 'relative',
        top: 5,
        right: '12px',
        cursor: 'pointer',
        '&:hover > div': {
            color: 'gray',
        },
        '&:hover > div > svg': {
            background: '#326dff',
        }
    },
    copyLinkIcon: {
        position: 'relative',
        top: '-6px',
        left: '-7px',
        transform: 'rotate(45deg)',
        '& > svg': {
            fontSize: 18,
            padding: 4,
            fill: 'white',
            background: '#ccc',
            borderRadius: '50%',
        }
    },
    copyLinkText: {
        fontSize: 12,
        fontWeight: 400,
        color: '#ccc',
        textDecoration: 'underline',
    },
    alertWrapper: {
        position: 'absolute',
        top: '0',
        left: '0',
    },
    copyLinkIconActive: {
        '& > svg': {
            background: '#326dff',
        }
    },
    copyLinkTextActive: {
        color: '#326dff',
    }
}));