import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        marginTop: 16,
        color: 'white',
        textTransform: 'capitalize',
    },
    tableContainer: {
      marginTop: 30,
      marginBottom: 5,
      border: '1px solid #DADCE0',
      boxSizing: 'border-box',
      borderRadius: 5,
    //   '&::-webkit-scrollbar': {
    //     webkitAppearance: 'none',
    //   },
    //   '&::-webkit-scrollbar:vertical': {
    //     webkitAppearance: 'none',
    //   },
    //   '&::-webkit-scrollbar:horizontal': {
    //     webkitAppearance: 'none',
    //   },
    //   '&::-webkit-scrollbar-thumb': {
    //     backgroundColor: 'rgba(0, 0, 0, .5)',
    //     borderRadius: 10,
    //     border: '2px solid #ffffff',
    //   },
    //   '&::-webkit-scrollbar-track': {
    //     borderRadius: 10,  
    //     backgroundColor: '#ffffff',
    //   },
      [theme.breakpoints.down('960')]: {
        width: '100%',
      }
    },
    table: {
        display: 'block',
        maxWidth: 'calc(100vw - 67px)',
        width: '100%',
        [theme.breakpoints.down('1024px')]: {
            display: 'block',
            maxWidth: '100%',
        }
    },
    tablerow: {
        verticalAlign: 'top',
    },
    paper: {
        padding: 3,
    },
    lastTimeWork: {
        marginTop: 10,
        marginBottom: 25,
        display: 'flex',
    },
    lastTimeWorkLabelIcon: {
        width: 20,
        height: 20,
        marginLeft: 10,
    },
    timeOfReceiptWrap: {
        position: 'relative',
        marginLeft: 45,
        '& input#timeOfReceipt': {
            width: 328,
            height: 44,
            fontSize: 14,
            lineHeight: '20px',
            color: '#000',
            border: '1px solid #476282',
            borderRadius: 5,
            padding: '0px 60px 0 6px',
            boxSizing: 'border-box',
        },
    },
    timeOfReceiptTextField: {
        height: 44,
        '& .MuiInput-input:disabled': {
            background: '#f5f5f5',
            border: '1px solid transparent',
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        },
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none'
        },
    },
    timeOfReceipt: {
        
    },
    clockIcon: {
        position: 'absolute',
        top: 12,
        right: 15,
    },
    loadingWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    loading: {
        margin: '16px auto',
    }
}));