import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { addClientRows } from '../../../reducers/clientTable';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Pagination = (props) => {
    const { pagination, setPagination } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const table = useSelector(state => state.clientTable.table);
    const allClients = useSelector(state => state.clientTable.allClients);
    const lastKey = useSelector(state => state.clientTable.lastKey);
    const searchValue = useSelector(state => state.clientTable.searchValue);

    const clickArrowBack = () => {
        console.log('--- back ---');
        setPagination(pagination - 1);

        let count =  props.pageView * (pagination - 1);
        let removeData = [...allClients];
        removeData.length = count;
        let arrRows = removeData.slice(-props.pageView);

        if (count < allClients.length && props.disabledArrow) {
            props.setDisabledArrow(false);
        } 

        dispatch(addClientRows(arrRows));

    }

    const getNextSearch = (pageView, key) => {
        let count =  pageView * (pagination + 1);
        let tableData = [...table];
        let arrRows = tableData.splice(pageView);

        props.setPreload(true);
        setTimeout(() => {
            dispatch(addClientRows(arrRows));
            props.setPreload(false);
        }, 200);
    }

    const clickArrowNext = () => {
        console.log('--- next ---');

        setPagination(pagination + 1);
        
        if (!searchValue) {
            let count =  props.pageView * (pagination + 1);
            let allClientsTable = [...allClients];
            allClientsTable.length = count;
            let arrRows = allClientsTable.slice(props.pageView * pagination);

            if (count > allClients.length) {
                props.setDisabledArrow(true);
            }

            dispatch(addClientRows(arrRows));
        } else {
            getNextSearch(props.pageView, lastKey);
        }
        
    }

    return (
        <div className={classes.root}>
            <ArrowBackIosIcon 
                onClick={pagination > 1 ? clickArrowBack : null} 
                className={classes.iconLeft} 
                style={{ fill: pagination > 1 ? 'inherit' : 'gray' }}
            />
                <div className={classes.count}>{pagination}</div>
            <ArrowForwardIosIcon 
                onClick={!props.disabledArrow ? clickArrowNext : null} 
                className={classes.iconRight}
                style={{ fill: !props.disabledArrow ? 'inherit' : 'gray' }}
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    iconLeft: {
        paddingRight: 5,
        cursor: 'pointer',
        '&:hover': {
            fill: theme.palette.primary.main,
        }
    },
    iconRight: {
        paddingLeft: 10,
        cursor: 'pointer',
        '&:hover': {
            fill: theme.palette.primary.main,
        }
    }
}));

export default Pagination;