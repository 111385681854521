import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addClientTable } from '../../../../reducers/clientTable';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core';

import dots from '../../../../assets/img/dots-table.jpg';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  display: 'flex',
//   overflow: 'auto',
});

const getHeadlineStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? 'red' : 'green',
    color: '#7f8690',
    ...draggableStyle
});

const TableComponent = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const rows = useSelector(state => state.clientTable.rows);
    const table = useSelector(state => state.clientTable.table);
    const columns = useSelector(state => state.clientTable.columns);

    useEffect(() => {
        const isName = columns.findIndex(el => el === 'Имя') !== -1;
        const isPhone = columns.findIndex(el => el === 'Телефон') !== -1;
        const isCity = columns.findIndex(el => el === 'Город') !== -1;
        const isDate = columns.findIndex(el => el === 'Дата установки') !== -1;
        const isGender = columns.findIndex(el => el === 'Пол') !== -1;
        const isDiscount = columns.findIndex(el => el === 'Скидка') !== -1;
        const isPlatform = columns.findIndex(el => el === 'Платформа') !== -1;

        let dataItems = [
            {id: 'ids', content: []},
            {id: 'name', content: []},
            {id: 'phone', content: []},
            {id: 'city', content: []},
            {id: 'birthday', content: []},
            {id: 'gender', content: []},
            {id: 'discount', content: []},
            {id: 'platform', content: []}
        ];

        rows.forEach((row, index) => {
            let ids = dataItems.find(t => t.id === 'ids');
            let name = dataItems.find(t => t.id === 'name');
            let phone = dataItems.find(t => t.id === 'phone');
            let city = dataItems.find(t => t.id === 'city');
            let birthday = dataItems.find(t => t.id === 'birthday');
            let gender = dataItems.find(t => t.id === 'gender');
            let discount = dataItems.find(t => t.id === 'discount');
            let platform = dataItems.find(t => t.id === 'platform');

            ids.content.push(index);
            isName && name.content.push(row.name);
            isPhone && phone.content.push(row.phone);
            isCity && city.content.push(row.city);
            isDate && birthday.content.push(row.birthday);
            isGender && gender.content.push('-');
            isDiscount && discount.content.push('-');
            isPlatform && platform.content.push('-');
        });

        dataItems = dataItems.filter(el => el.content.length !== 0);

        dispatch(addClientTable(dataItems));
    }, [columns, rows]);

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const updateItems = reorder(
            table,
            result.source.index,
            result.destination.index
        );

        // console.log('updateItems', updateItems);

        dispatch(addClientTable(updateItems));
  }

  const getHeadLine = (id) => {
      switch(id) {
        case 'ids': 
            return '№';
        case 'name':
            return 'ФИО';
        case 'phone':
            return 'НОМЕР ТЕЛЕФОНА';
        case 'city':
            return 'ГОРОД';
        case 'birthday':
            return 'ДАТА УСТАНОВКИ';
        case 'gender':
            return 'ПОЛ';
        case 'discount':
            return 'СКИДКА';
        case 'platform':
            return 'ПЛАТФОРМА';
        default:
            return '';
      }
  }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                >
                    {table.map((item, index) => (
                        <Draggable 
                            key={item.id} 
                            draggableId={item.id} 
                            index={index}
                            // style={getItemStyle(
                            //     snapshot.isDragging,
                            //     provided.draggableProps.style
                            // )}
                        >
                            {(provided, snapshot) => (
                                <div 
                                    className={classes.draggable}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    <div 
                                        className={`${classes.tableElement} ${item.id === 'ids' ? 'ids': item.id === 'gender' ? 'gender' : item.id === 'discount' ? 'discount' : item.id === 'platform' ? 'platform' : ''}`}
                                        >
                                        <div 
                                            className={classes.headline}  
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            // style={{ background: 'red' }}
                                        >
                                            <img className={classes.dots} src={dots} alt="dots" />
                                            {getHeadLine(item.id)}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
            </Droppable>
        </DragDropContext>
    )
}

const useStyles = makeStyles({
    draggable: {
        display: 'flex',
        backgroundColor: '#ffffff',
    },
    tableElement: {
        '&.ids > div': {
              width: 75,
        },
        '&.gender > div': {
              width: 75,
        },
        '&.discount > div': {
              width: 100,
        },
        '&.platform > div': {
              width: 140,
        },
    },
    headline: {
        position: 'relative',
        width: 200,
        paddingLeft: 30,
        paddingRight: 15,
        // margin: '0 16px',
        lineHeight: '50px',
        backgroundColor: '#ffffff',
        height: 50,
        borderRadius: 10,
        boxSizing: 'border-box',

        '& > img': {
            display: 'none',
        },

        '&:hover': {
            background: '#f2f2f2',
            color: '#7f8690',
        },

        '&:hover > img': {
            display: 'block',
        }
    },
    dots: {
        position: 'absolute',
        top: 'calc(50% - 11px)',
        left: 8,
        width: 'auto',
        height: 20,
    }
});

export default TableComponent;