import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
    root: {
       marginBottom: 24,
       display: 'flex',
       flexWrap: 'wrap',
       justifyContent: 'space-between',
       '@media screen and (max-width: 650px)': {
            flexDirection: 'column',
        }
    },
    firstInput: {
        // color: '#f5f5f5',
    },
    filterListIconWrap: {
        marginLeft: 30,
        padding: '5px 12px',
        border: '1px solid #326dff',
        borderRadius: 5,
        color: '#326dff',
        cursor: 'pointer',
        '&:hover': {
            background: '#326dff',
            '& > svg': {
                color: 'white',
            }
        },
        '& > svg': {
            width: 14,
        }
    },
    filterListIcon: {
        color: '#326dff',
    },
    firstWrap: {
        display: 'flex',
        '@media screen and (max-width: 650px)': {
            // margin: '0 auto',
            marginBottom: 16,
        }
    },
    lastWrap: {
        display: 'flex',
        '@media screen and (max-width: 650px)': {
            flexWrap: 'wrap',
            // margin: '0 auto'
        }
    },
    searchWrapper: {
        '@media screen and (max-width: 650px)': {
            // margin: '0 auto',
            marginBottom: 16,
            flexWrap: 'nowrap',
        }
    },
    searchItem: {
        display: 'flex',
        '@media screen and (max-width: 650px)': {
            margin: 0,
        }
    },
    alert: {
        '& > div.MuiAlert-message': {
            display: 'block',
        },
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        margin: '0 8px',
        '&:hover': {
            textDecoration: 'underline',
        }
    },
    dropSelectList: {
        // marginTop: 4,
        // marginRight: 16,
        '& > div > div.makeStyles-root-442.MuiSelect-select.MuiSelect-select': {
            padding: '0px 29px 0px 10px',
        },
        '& > div > svg': {
            right: 4,
        },
        '@media screen and (max-width: 500px)': {
            width: '100%',
            marginBottom: 6,
            '& > div': {
                borderRight: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 0,
            },
            '& > div > div': {
                textAlign: 'left',
            }
        }
    }
}));