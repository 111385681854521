import React from 'react';
import withWidth from '@material-ui/core/withWidth';
import { withFirebase } from '../../../firebase';
import { withRouter } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import {Switch, Route, Redirect} from 'react-router-dom';

import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import Profile from '../Profile';
import Navigation from '../Navigation';
import Loading from '../../../components/Loading';

import Reviews from '../../Reviews/ReviewsContainer';
import Push from '../../push/pushContainer';
import DisableApp from '../../disableApp/disableAppContainer';
import PayCard from '../../payCard/payCardContainer';
import Categories from '../../menu/categories/categoriesContainer';
import WorkTime from '../../workTime/workTimeContainer';
import Delivery from '../../delivery/deliveryContainer';
import Discount from '../../discounts/discountsContainer';
import Promotion from '../../promotion/promotionContainer';

import Products from '../../products/productsContainer';
import AddCategory from '../../products/categories/addCategory';
import EditCategory from '../../products/categories/editCategory';
import AddProducts from '../../products/addProducts/addProductsContainer';
import EditProducts from '../../products/editProducts/editProductsContainer';
import SortMode from '../../products/sortMode';
import SortModeProducts from '../../products/sortMode/products';
import SortModeCategories from '../../products/sortMode/categories';
import EditProduct from '../../products/editProducts/EditProduct/editProductContainer';
import DefaultPage from '../../defaultPage';
import LoadControl from '../../../scenes/loadControl';
import ClientList from '../../clientList';

import { MenuBurger, MenuBurgerClose } from './icons';
import useStyles from './styles';

const DefaultScreen = (props) => {
    const classes = useStyles();
    const { 
      open, 
      width, 
      preloader, 
      handleDrawerClose, 
      openSettings, 
      chooseCity, 
      keyShop, 
      loadCity, 
      city, 
      defaultCity, 
      defaultHiddenCategories, 
      defaultHiddenProducts, 
      getDefaultHiddenCategories, 
      getDefaultHiddenProducts, 
      openCategory, 
      openProducts, 
      handleOpen, 
      handleProductOpen, 
      adminPermission, 
      adminSettings, 
      categoriesMenu, 
      productsMenu, 
      categoriesPromocode, 
      productsPromocode, 
      isDirtyWorkingHours, 
      handleIsDirtyWorkingHours, 
      handleDrawerOpen, 
      handleProductsMenu, 
      getDefaultCity, 
      handleCategoriesPromocode, 
      handleProductsPromocode, 
      handleCategoriesMenu, 
    } = props;

    return(
        <div className={classes.root}>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open && width !== 'sm' && width !== 'xs',
          })}
          color='inherit'
        >
          <Navigation open={open}
              preloader={preloader}
              handleDrawerClose={handleDrawerClose}
              handleOpenSettings={openSettings}
              openSettings={openSettings}
              chooseCity={chooseCity}
              city={keyShop}
              loadCity={loadCity}
              listCity={city}
              defaultCity={defaultCity && defaultCity}
              defaultHiddenCategories={defaultHiddenCategories}
              defaultHiddenProducts={defaultHiddenProducts}
              getDefaultHiddenCategories={getDefaultHiddenCategories}
              getDefaultHiddenProducts={getDefaultHiddenProducts}
              openCategory={openCategory}
              openProducts={openProducts}
              handleOpen={handleOpen}
              handleProductOpen={handleProductOpen}
              adminPermission={adminPermission}
              adminSettings={adminSettings}
              categoriesMenu={categoriesMenu}
              productsMenu={productsMenu}
              categoriesPromocode={categoriesPromocode}
              productsPromocode={productsPromocode}
              isDirtyWorkingHours={isDirtyWorkingHours}
              handleIsDirtyWorkingHours={handleIsDirtyWorkingHours}
            />
          <Toolbar>
            {!open && width !== 'sm' && width !== 'xs' &&  <span className={classes.headerLogo}>E-APP</span>}
            {width !== 'md' && width !== 'lg' && width !== 'xl' && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                // className={clsx(classes.menuButton, open && classes.hide)}
              >
                {!open  && <div className={classes.menuBurger}><MenuBurger /></div>}
                {open && <div className={classes.menuBurger}><MenuBurgerClose /></div>}
              </IconButton>
            )}
            <div className={classes.toolbarContainer}>
              <Profile signOut={props.firebase.doSignOut} login={props.login}/>
            </div>
          </Toolbar>
        </AppBar>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
        {!loadCity ? <Switch>
                {adminPermission && (adminPermission.pushControl || adminPermission.pushControl === undefined) && <Route  path={ROUTES.PUSH} render={() => <Push/>}/>}
                {adminPermission && (adminPermission.disableApp || adminPermission.disableApp === undefined) && <Route  path={ROUTES.DISABLEAPP} render={() => <DisableApp/>}/>}
                <Route  path={ROUTES.REVIEWS} render={() => 
                  (adminPermission ? (adminPermission.productReviews || adminPermission.appReviews || adminPermission.orderReviews !== undefined ? adminPermission.orderReviews : true) : true) 
                    ? <Reviews 
                        reviews={adminPermission && adminPermission.productReviews} 
                        apps={adminPermission && adminPermission.appReviews}
                        orders={adminPermission && adminPermission.orderReviews !== undefined ? adminPermission.orderReviews : true}
                      />
                    :  <Redirect to="/" />
                } />
                <Route path={ROUTES.LOAD_CONTROL} render={() => props.adminPermission && !props.adminPermission.controlDeliveryInterval
                  ? <Redirect to="/" />
                  : <LoadControl />} />
                <Route path={ROUTES.CLIENTLIST} render={() => adminPermission && !adminPermission.clientsList
                  ? <Redirect to="/" />
                  : <ClientList />} />
                <Route path={ROUTES.PROMOTION} render={() => adminPermission && !adminPermission.promotion
                  ? <Redirect to="/" />
                  : <Promotion />} />
                <Route path={ROUTES.PAY} render={() => adminPermission && !adminPermission.payCard
                  ? <Redirect to="/" />
                  : <PayCard />} />
                <Route path={ROUTES.WORKTIME} render={() => 
                  <WorkTime 
                    open={open}  
                    isDirtyWorkingHours={isDirtyWorkingHours}
                    handleIsDirtyWorkingHours={handleIsDirtyWorkingHours} />
                } />
                <Route  path={ROUTES.DELIVERY} render={() => adminPermission && !adminPermission.delivery
                  ? <Redirect to="/" />
                  : <Delivery /> } />
                <Route  path={ROUTES.CATEGORIES} render={() => adminPermission && !adminPermission.menu
                    ? <Redirect to="/" />
                    : <Categories
                        handleCategoriesMenu={handleCategoriesMenu} 
                        handleProductsMenu={handleProductsMenu}
                        getDefaultHiddenCategories={getDefaultHiddenCategories}
                        getDefaultHiddenProducts={getDefaultHiddenProducts}
                      />
                  } />
                <Route  path={ROUTES.DISCOUNTS} render={() => 
                  (adminPermission && !adminPermission.promoCodes) &&
                  (adminPermission && !adminPermission.saleFirstOrder) &&
                  (adminPermission && !adminPermission.bannerControl)
                    ? <Redirect to="/" />
                    : <Discount 
                        getDefaultCity={getDefaultCity}
                        handleCategoriesPromocode={handleCategoriesPromocode} 
                        handleProductsPromocode={handleProductsPromocode} />
                } />
                  <Route exact path={ROUTES.ADD_CATEGORY} render={() => <AddCategory />}/>
                  <Route path={ROUTES.EDIT_CATEGORY} render={() => <EditCategory />}/>
                  <Route path={ROUTES.SORT_MODE}  render={() => <SortMode />}/>
                  {/*<Route path={ROUTES.SORT_MODE_CATEGORIES} render={() => <SortModeCategories />}/>
                  <Route path={ROUTES.SORT_MODE_PRODUCT} render={() => <SortModeProducts/>}/>*/}
                  <Route path={ROUTES.ADD_PRODUCTS} render={() => 
                    adminPermission && adminPermission.ourAdmin
                      ? <AddProducts />
                      : <Redirect to="/" /> }/>
                  <Route exact path={ROUTES.EDIT_CATEGORIES_ID} render={() => <EditProducts  open={open}  /> } />
                  <Route exact path={ROUTES.EDIT_PRODUCTS} render={() => 
                    adminPermission && adminPermission.ourAdmin
                      ? <EditProducts open={open} />
                      : <Redirect to="/" />} /> 
                  <Route path={ROUTES.EDIT_PRODUCTS_ID} render={() => <EditProduct />}/>
                  <Route path={ROUTES.PRODUCTS} render={() => 
                    adminPermission && adminPermission.ourAdmin
                      ? <Products />
                      : <Redirect to="/" /> } />
                <Route  path={'*'} render={(props) => {
                  let urlMatch = props.location.pathname.match('page/uploads/');
                  if (urlMatch) return null;

                  return <DefaultPage />
                }} />
            </Switch> : <div className={classes.loading}><Loading color={'#326dff'}/></div>}
        </main>
      </div>
    )
}

export default withWidth()(withRouter(withFirebase(DefaultScreen)));