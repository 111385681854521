import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const TapApp = () => {
    const classes = useStyle();

    return (
        <div className={classes.container}>В разработке</div>
    )
}

const useStyle = makeStyles({
    container: {
        // background: 'red',
    }
});

export default TapApp;