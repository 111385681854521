import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        // backgroundColor: 'red',
    },
    desctopWrapper: {
        padding: '24px 16px 24px 16px',
        background: 'white',
        border:' 0.5px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: '5px 5px 0px 0px',
    },
    tabContent: {
        [theme.breakpoints.down("600")]: {
            padding: '16px 8px',
            background: 'white',
            border: '1px solid #E5E5E5',
            borderRadius: 10,
        },
    },
    title: {
        marginBottom: 16,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    },
    titleMobile: {
        marginTop: 16,
        marginBottom: 8,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    }
}));