import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDataForm } from '../../reducers/workingHours';
import { createTextMask } from 'redux-form-input-masks';
import { makeStyles } from '@material-ui/core/styles'
import { Field } from 'redux-form';
import { daysArr } from './other';

const renderField = ({ 
    input, 
    meta, 
    ...rest 
  }) => {
    const { dataForm, dispatchToRedux, addDataForm } = rest;
    const inputVal = input.value ? input.value.replace(/:/g, '').replace(/-/g, '').replace(/_/g, '') : null;
    const initVal = meta.initial ? meta.initial : null;
    const value = input.value.split('-');
    const val1 = value[0].replace(':', '');
    const val2 = value[1].replace(':', '');

    const inputSelector = inputVal && inputVal.length === 8 && initVal && initVal.length === 8;

    if (inputSelector) {
       if (+inputVal !== + initVal) {
        let newDataForm = { ...dataForm };

        for (let day in dataForm) {
            const item = dataForm[day];
            let name = input.name.split('.')[1]
            let days = [...daysArr];
            let index = days.indexOf(name);
            const isCheckbox = !!item.checkbox;
            const changeDay = days[index];

            if (day === changeDay) {
                if (!isCheckbox) {
                    let dataDay = { 
                        ...item,
                        begin: val1, 
                        end:val2,
                        deliveryEnd: val2,
                    };
                    newDataForm[changeDay] = dataDay;
                }
            }
        }
        addDataForm(dispatchToRedux, newDataForm);
       }
    }
    
    return (
      <div>
          <input
            value={input.value}
            onChange={input.onChange} 
            {...rest} />
            {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    )
}

const InputInfoWorkingTime = ({ data, name }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataForm = useSelector(state => state.workingHours.dataForm);
    const isDisabled = data.checkbox ? true : false;
    const myCustomMaskDefinitions = {
        2: {
            regExp: /[0-2]/,
          },
        4: {
            regExp: /[0-4]/,
          },
        5: {
            regExp: /[0-5]/,
          },
        9: {
          regExp: /[0-9]/,
        },
      };
    const phoneMask = createTextMask({ 
        pattern: '29:59-29:59',
        maskDefinitions: myCustomMaskDefinitions, 
    });
    return (
        <Field 
            name={name}
            component={renderField}
            className={classes.calendarField} 
            disabled={isDisabled}
            dataForm={dataForm}
            dispatchToRedux={dispatch}
            addDataForm={addDataForm}
            {...phoneMask} 
        />
    )
}

const useStyles = makeStyles({
    calendarField: {
        width: 140,
        textAlign: 'center',
        height: 30,
        fontSize: 16,
        lineHeight: '30px',
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        overflow: 'hidden',
        '& .MuiInput-input': {
            textAlign: 'center',
            padding: '5px 0 7px',
            color: '#476282',
        },
        '& .MuiInput-input:disabled': {
            background: '#f5f5f5',
            border: '1px solid transparent',
            color: 'rgba(71, 98, 130, 0.5)',
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        },
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none'
        },
    },
});

export default InputInfoWorkingTime;