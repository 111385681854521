import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';

const DialogComponent = ({ 
    children, 
    open,
    title,
    handleClose, 
}) => {
    const classes = useStyles();

    return (
        <Dialog classes={{
            paper: classes.dialogPaper,
        }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className={classes.iconsWrapper}>
                <CloseIcon onClick={() => handleClose()} className={classes.closeIcon} />
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    {title}
                </DialogTitle>
            </div>
            <DialogContent className={classes.dialogContent}>
                {children}
            </DialogContent>
        </Dialog>
    )
};

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 1057,
        padding: 24,
        [theme.breakpoints.down('600')]: {
            width: '100%',
            margin: '0 10px',
            padding: '24px 16px 16px',
        },
    },
    dialogContent: {
        [theme.breakpoints.down('600')]: {
            padding: 0,
        },
    },
    dialogTitle: {
        padding: 0,
        '& h2': {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '25px',
        }
    },
    iconsWrapper: {
        display: 'flex',
    },
    closeIcon: {
        marginRight: 16,
        color: '#476282',
        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        }
    },
}));

export default DialogComponent;