import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux';
import { addDataForm } from '../../reducers/workingHours';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

const FormControlGroup = ({ data, ...props }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataForm = useSelector(state => state.workingHours.dataForm);

    const handleChange = () => {
        const { name } = props;
        const isCheckbox = !data.checkbox;

        for (let key in dataForm) {
            if (key === name) {
                let newDataForm = { ...dataForm };

                if (isCheckbox) {
                    newDataForm[key] =  { 
                        ...data, 
                        begin: '00:00', 
                        end: '00:00', 
                        deliveryEndWrite: true,
                        deliveryEnd: '23:00',
                        checkbox: isCheckbox,
                    }
                }

                if (!isCheckbox) {
                    newDataForm[key] =  { 
                        ...data, 
                        deliveryEndWrite: false,
                        checkbox: isCheckbox,
                    }
                }
                addDataForm(dispatch, newDataForm);
            }
        }
    };

    return (
        <FormControl className={classes.formControl} component="fieldset">
            <div className={classes.checkboxLabel}>
                <Checkbox
                    className={classes.checkbox}
                    id={`weekendTime-${props.name}`}
                    checked={data.checkbox}
                    color={'primary'}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                <label 
                    htmlFor={`weekendTime-${props.name}`} 
                    className={classes.typographyLabel} 
                    style={{color: data.checkbox ? '#326DFF' : ''}}
                >
                    Выходной
                </label>
            </div>
        </FormControl>
    );
}

const useStyles = makeStyles({
    formControlLabel: {
        marginRight: 0,
        '&:last-child': {
            marginBottom: 0,
        },
        '& .MuiTypography-body1': {
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '14px',
            letterSpacing: '0.003em',
            marginLeft: 8,
            color: '#476282',
        }
    },
    formControl: {
        marginBottom: 16,
    },  
    radio: {
        padding: 0,
        color: '#476282',
    },
    checkboxLabel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 6,
        '&:last-child': {
            marginBottom: 0,
        }
    },
    typographyLabel: {
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '14px',
        letterSpacing: '0.003em',
        color: '#476282',
        cursor: 'pointer',

        maxWidth: 80,
        width: '100%',
        textAlign: 'left',
    },
  });

export default FormControlGroup;