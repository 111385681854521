import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../../firebase';
import { withActions } from '../../../../api';
import { useSelector, useDispatch } from 'react-redux';
import { addCategory, updateCategoriesOrder, updateCategories, updateCategoriesDispatch } from '../../../../reducers/shops';

import AddCategoryForm from './form';
import useStyles from './styles';

let Categories = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const categories = useSelector(state => state.shops.categories);
    const cities = useSelector(state => state.shops.shops);
    const language = useSelector(state => state.shops.defaultLanguage);
    const languages = useSelector(state => state.shops.languages);
    const login = useSelector((state) => state.auth.login);

    const [isSelectOpen, setIsSelectOpen] = useState(null);
    const [preloder, setPreloader] = useState(false);
    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedTerminals, setSelectedTerminals] = useState([]);

    const isDebug = JSON.parse(localStorage.getItem('isDebug'))

    useEffect(() => {
        if (cities.length === 1) setSelectedCities([cities[0].address]);
    }, [cities]);

    useEffect(() => {
        if (cities.length === 1 && selectedCities.length > 0) {
            let terminals = getTerminals();

            setSelectedTerminals(terminals);
        }
    }, [selectedCities, cities]);


    const changeSelectClose = () =>  setIsSelectOpen(false);
    const changeSelectOpen = () => setIsSelectOpen(true);

    const handleChange = (event) => {
        const data = [...selectedTerminals];
        const index = data.findIndex(t => t.id === event.target.name);
        data[index] = { ...data[index], visible: data[index].visible === "1" ? "0" : "1" };

        setSelectedTerminals(data)
    };

    const getTerminals = () => {
        let terminals = [];

        selectedCities.map(address => {
            let { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id, visible: "1" };
            });

            terminals.push(...deliveryTerminals);
        });

        return terminals;
    }

    const changeCity  = (data) => {
        let terminals = [];

        data.map(address => {
            let { spot_id, deliveryTerminals } = cities.find(city => city.address === address);

            deliveryTerminals.map((elem, index) => { 
                deliveryTerminals[index] = { ...elem, cityId: spot_id, visible: "1" };

                selectedTerminals.map(terminal => {
                    if (elem.id === terminal.id) {
                        let isVisible = false;

                        for (let key in terminal) {
                            if (key == 'visible') {
                                isVisible = true;
                            }
                        }

                        deliveryTerminals[index] = { ...terminal, cityId: spot_id, visible: terminal.visible };
                    }
                })
            });

            terminals.push(...deliveryTerminals);
        });

        setSelectedTerminals(terminals);
        setSelectedCities(data);
    }

    const createNewCategory = (data) => {
        let spots = [];
        let name = {};
        let allTerminals = [];

        for (let key in data) {
            name[key] = data[key];
        }
        
        cities.map(city => allTerminals.push(...city.deliveryTerminals));

        allTerminals.map((terminal, index) => {
            let spot = {
                spot_id: terminal.id,
                visible: "0",
            };

            spots[index] = spot;

            selectedTerminals.map(selectTerminal => {
                if (terminal.id === selectTerminal.id) {
                    spots[index] = {
                        spot_id: terminal.id,
                        visible: selectTerminal.visible
                    };
                }
            });

        });

        return {
            icon: "default",
            spots,
            name,
        };
    }

    const onSubmit = async (data) => {
        const category = createNewCategory(data);

        const newCategory = await props.addCategory({icon: category.icon, name: category.name}) 
        
        if (newCategory) {
            const hiddenOnSpots = category.spots.filter(item => item.visible === '0').map(item => ({spot_id: item.spot_id, category_id: newCategory.id }))
            const updatedCategory = {...newCategory, order: newCategory.id}
            await props.updateCategory(updatedCategory)
            await props.addHiddenSpotsCategory(hiddenOnSpots)
            const hiddenItemSpots = category.spots.filter(item => item.visible === '0')
            const visibleSpots = category.spots.filter(item => item.visible === '1')
            // categories.push({... updatedCategory, hiddenOnSpots: hiddenItemSpots, visibleSpots: visibleSpots, spots: category.spots})
            categories.push({...updatedCategory, hiddenOnSpots: hiddenItemSpots, spots: visibleSpots})
            console.log('categories after push', categories)
            // updateCategories(categories)
            updateCategoriesDispatch(dispatch, categories);

            props.history.push('/page/menu/products/');
        } else {
            alert("Что-то пошло не так ...");
        }



        // setPreloader(true);
        // props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/categories`).push(category)
        //     .then(res => {
        //         let data = { 
        //             [res.key]: { 
        //                 ...category,
        //                 id: res.key,
        //             } 
        //         };

        //         let categoriesOrderData = [];

        //         for (let key in categories) {
        //             categoriesOrderData.push(key);
        //         }

        //         categoriesOrderData.push(res.key);

        //         props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/Category/categoriesOrder`).update(categoriesOrderData)
        //             .then(res => {
        //                 console.log('data', data);
        //                 console.log('categoriesOrderData', categoriesOrderData);
        //                 addCategory(dispatch, data);
        //                 setPreloader(false);
        //                 updateCategoriesOrder(categoriesOrderData);
                        
        //                 props.history.push('/page/menu/products/');
        //             })
        //             .catch(error => {
        //                 console.error(error);
        //             });
        //     })
        //     .catch(error => {
        //         console.error(error);
        //         setPreloader(false);
        //     });
        //     if (login === "osama-sushi") {
        //     props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/updates`).update({ lastUpdateInMenu: Date.now() });
        //     }
    }

    return(
        <div className={classes.root}>
            <div className={classes.modal}>
               <AddCategoryForm
                    load={preloder} 
                    language={language}
                    languages={languages}
                    cities={cities}
                    selectedTerminals={selectedTerminals}
                    setSelectedTerminals={setSelectedTerminals}
                    isSelectOpen={isSelectOpen}
                    onSubmit={onSubmit}
                    handleChange={handleChange}
                    changeSelectClose={changeSelectClose}
                    changeSelectOpen={changeSelectOpen}
                    selectedCities={selectedCities}
                    changeCity={changeCity}
               />
            </div>
        </div>
    )
}

export default withActions(withFirebase(withRouter(Categories)));