import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../firebase'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormLabel from '@material-ui/core/FormLabel';

import TableForm from './TableForm';

const Title = ({ classes }) => (
  <FormLabel className={classes.formLegend} component="legend">
    <Typography className={classes.title}>Время работы</Typography>
  </FormLabel>
)

const WorkTime = (props) => {
    const classes = useStyles();

    return(
        <Grid style={{ width: props.open ? 'calc(100vw - 280px)' : '100%' }} container className={classes.workTimeWrap}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
             <Title classes={classes} />
              <TableForm {...props}  />
            </Paper>
          </Grid>
      </Grid>
    )
} 
const useStyles = makeStyles(theme => ({
  workTimeWrap: {
    width: 'calc(100vw - 285px) !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    }
  },
  paper: {
    padding: theme.spacing(2),
    paddingTop: 24,
    paddingBottom: 50,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 22,
    lineHeight: '26px',
    fontWeight: 'bold',
    letterSpacing: '0.003em',
    color: 'black'
  },
  arrowIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formControl: {
    width: '100%',
    marginTop: 24,
  },
  formLegend: {
    display: 'flex',
  },
  buttonLabel: {
    display: 'block',
    margin: '0 auto',
    marginTop: 20,
    textAlign: 'center',
    width: 200,
  },
  button: {
    width: 150,
    height: 42,
    background: '#326DFF',
    color: 'white',
    borderRadius: '5px',
    '&:hover': {
        background: '#326DFF',
    }
  },
  errorFormLabel: {
    marginTop: 30,
  },
  errorContent: {
    fontSize: 16,
    lineHeight: '24px',
    color: '#ccc'
  }
}))

export default withFirebase(withRouter(WorkTime));