import React from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputStyle: {
    width: '100%',
    marginBottom: 10,
    '& input':{
      fontSize: 16,
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& label':{
      fontSize: 14,
    },
    '&input:invalid': {
      color: '#F44336'
    },
    '& ::placeholder':{
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#326dff',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#326dff',
    },
    '&  .MuiInput-underline.Mui-error:after': {
      borderBottomColor: '#F44336',
    },
  },
}));

export default function CustomizedInputs({input, meta, ...props}) {
  const classes = useStyles();

  return (
    <TextField
          className={classes.inputStyle}
          label={props.placeholder}
          error={meta.touched && meta.invalid}
          helperText={meta.touched && meta.error}
          {...input}
          {...props}
        />
  )
};

