import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeIcon: {
        position: 'relative',
        top: 15,
        left: -15,
        cursor: 'pointer',
        '&:hover': {
            opacity: .75,
        }
    },
    count: {
        display: 'block',
        fontSize: 26,
        lineHeight: 2,
        color: 'black',
    },
    closeButton: {
        marginRight: 30,
        background: 'white',
        border: '1px solid #326dff',
        color: '#326dff',
        borderRadius: 3,
        height: 45,
        minWidth: 180,
        width: 'auto',
        fontSize: 14,
        marginTop: 10,
        '& span': {
          textTransform: 'capitalize',
        },
        '&:hover': {
            background: '#326dff', 
            color: 'white', 
        },
        '&:disabled': {
          background: '#F1F3F4', 
          color: '#476282', 
          border: '1px solid #F1F3F4', 
        },
    },
    buttonWrap: {
        display: 'flex',
        marginTop: 16,
        justifyContent: 'flex-end',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      marginBottom: 16,
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    textarea: {
        width: '100%',
    },
    textareaWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: 2,
    },
    textareaLabel: {
        fontSize: 14,
    },
    textareaCount: {
        fontSize: 14,
        fontWeight: 500,
    }
}));