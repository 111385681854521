import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export default makeStyles(theme => ({
    root: {
    display: 'flex',
    background: '#F1F3F4;',
    },
    loading: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        // boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        boxShadow: 'none',
        zIndex: 99,
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    toolbarContainer: {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'flex-end'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
    content: {
        width: '100vw',
        flexGrow: 1,
        padding:' 24px 24px 0 20px;',
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 240,
        boxSizing: 'border-box',
        // [theme.breakpoints.up("md")]: {
        //     marginLeft: -drawerWidth,
        // },
        [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        padding: '16px',
        },
        position: 'relative',
        minHeight: '100vh',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    position: 'relative',
    minHeight: '100vh',
    menuBurger: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 55,
        height: 42,
        border: '1px solid #D3D9DD',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.up("1024")]: {
        display: 'none',
        }
    },
    menuBurgerClose: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 55,
        height: 42,
        border: '1px solid #D3D9DD',
        borderRadius: 5,
        [theme.breakpoints.up("1024")]: {
        display: 'none',
        }
    },
    headerLogo: {
        fontSize: 18,
        fontWeight: 500,
        color: '#326dff',
        paddingLeft: 10,
    },
}));