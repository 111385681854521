/* eslint-disable array-callback-return */
import React, { Component } from "react";

import PayCard from "./payCard";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
	updateKeyShop,
	editShops,
	updateSettings,
	updatePayCard,
} from "../../reducers/shops";
import { withFirebase } from "../../firebase";

class PayCardContainer extends Component {
	constructor() {
		super();

		this.state = {
			checked: false,
			keyShop: null,
			loadStatus: false,
			city: [],
		};
	}

	componentDidMount() {
		this.props.updateKeyShop(this.props.match.params.city);
		this.setState({
			keyShop: this.props.match.params.city,
		});
	}

	updateStatusApp = () => {
		let value = this.props.shops[this.props.match.params.city].card
			? false
			: true;

		this.setState({
			checked: this.props.shops,
		});

		this.props.editShops({
			key: this.props.match.params.city,
			params: "card",
			value,
		});
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.props.match.params.city}/`
			)
			.update({ card: value });
	};

	updateCity = (updateData) => {
		let names = [];

		for (let key in updateData) {
			names.push(key);
		}

		let data = {
			id: this.props.match.params.city,
			list: updateData,
			names,
		};
		this.props.updatePayCard(data);
		this.setState({ city: data.list });
	};

	sendData = (data) => {
		let updateData = {};
		const dataNames = ["fondy", "liqPay", "wayForPay", "yandex", "portmone"];
		const city =
			this.props.shops[this.props.match.params.city].cardPaymentSystem;

		console.log("data *** -->>", data);

		dataNames.map((name) => {
			for (let key in city) {
				if (key === name) {
					// console.log('name', name);
					updateData[name] = data;
				}
			}
		});

		this.setState({ loadStatus: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.props.match.params.city}/cardPaymentSystem`
			)
			.update(updateData)
			.then((res) => {
				this.setState({ loadStatus: false });
				this.updateCity(updateData);
			});
	};

	render() {
		return (
			<>
				<PayCard
					updateStatusApp={this.updateStatusApp}
					sendData={this.sendData}
					loadStatus={this.state.loadStatus}
					keyShop={this.props.match.params.city}
					closedAlert={this.props.closedAlert}
					shops={this.props.shops[this.props.match.params.city]}
					city={this.state.city}
					editShops={this.editShops}
					checked={this.state.checked}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, {
	updateKeyShop,
	editShops,
	updateSettings,
	updatePayCard,
})(withRouter(withFirebase(PayCardContainer)));
