import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductsForm from "./productsForm";
import { withFirebase } from "../../../firebase";
import { withRouter } from "react-router-dom";
import { withActions } from "../../../api";
import Preloader from "../preloader/preloader";
import axios from "axios";
import { updateProduct, updateProductsDispatch } from "../../../reducers/shops";

const AddProduct = (props) => {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth.login);
	const defaultLanguage = useSelector((state) => state.shops.defaultLanguage);
	const languages = useSelector((state) => state.shops.languages);
	const cities = useSelector((state) => state.shops.shops);
	const categories = useSelector((state) => state.shops.categories);

	const [sortLanguages, setSortLanguages] = useState([]);
	const [citiesSpot, setCitiesSpot] = useState(null);
	const [preloader, setPreloader] = useState(false);
	const [isOpenCities, setIsOpenCities] = useState(false);

	useEffect(() => {
		getLanguages();
	}, []);

	const getLanguages = () => {
		let data = [...languages];

		if (data.length > 0 && data) {
			data = data.sort((a, b) => {
				if (a.key !== defaultLanguage) {
					return 1;
				} else {
					return -1;
				}
			});
		}

		setSortLanguages(data);
	};

	const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
	const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

	console.log("is open cities", isOpenCities);

	const createProduct = (data) => {
		let languagesName = {};
		let languagesDescription = {};

		for (let index in sortLanguages) {
			let key = sortLanguages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		let spots = {};
		let allTerminals = [];

		cities.map((city) => allTerminals.push(...city.deliveryTerminals));

		allTerminals.map((terminal, index) => {
			let spot = {
				price: "0",
				spot_id: terminal.id,
				visible: "0",
			};
			spots[index] = spot;
		});

		console.log("allTerminals", allTerminals);
		console.log("data", data);

		for (let key in spots) {
			const terminal = spots[key];

			citiesSpot.map(({ id, visible }, index) => {
				if (String(terminal.spot_id) === String(id)) {
					console.log("### init ###");

					let spot = {
						price:
							data.firstPrice && !isOpenCities
								? String(Math.round(data.firstPrice * 100))
								: String(Math.round(data.terminals[terminal.spot_id] * 100)),
						spot_id: id,
						visible,
					};

					spots[key] = spot;
				}
			});
		}

		const category = categories[data.categories];

		setPreloader(true);
		let product = {
			categoryId: category.id,
			name: languagesName,
			description: languagesDescription,
			price: String(Math.round(data.firstPrice * 100)),
			// spots,
		};

		const spotsArray = Object.values(spots).map((obj) => obj);

		return { product, spots: spotsArray };
	};

	const onSubmit = (data) => {
		// console.log("data ****", data);

		let { product, spots } = createProduct(data);

		// console.log("product **** ", product);
		// if (login === "osama-sushi") {
		// 	props.firebase.bd
		// 		.ref(
		// 			`${
		// 				JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
		// 			}/updates`
		// 		)
		// 		.update({ lastUpdateInMenu: Date.now() });
		// }

		// console.log("product --->>", product);

		let base_url =
			window.location.hostname == "localhost:3000"
				? "localhost"
				: window.location.hostname;

		let file = new FormData();
		file.append("photo", data.photo[0]);
		file.append("login", login);
		axios
			.post(`https://e-admin.com.ua/photo/index.php`, file)
			.then(async (res) => {
				setPreloader(false);

				const namePhoto = res.data.image_name.split(" ")[0];
				const isSuccess = res.data.check_upload_image;
				// console.log("res ---- >>> ", res, isSuccess);

				// product.photo = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;
				// product.namePhoto = namePhoto;

				product.image = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;

				if (isSuccess) {
					setPreloader(true);
					const res = await props.addProducts([product]);

					console.log("[product]", [product]);
					console.log("res **** ,", res);

					if (res.length > 0) {
						const product = res[0];
						let hiddenOnSpots = [];
						let priceOnSpots = [];

						if (isOpenCities) {
							console.log("is open cities", isOpenCities);

							const filteredHiddenSpots = spots.filter(
								(item) => item.visible === "0"
							);

							hiddenOnSpots = filteredHiddenSpots.map((item) => ({
								spot_id: item.spot_id,
								product_id: product.id,
							}));

							priceOnSpots = spots.map((item) => ({
								spot_id: item.spot_id,
								product_id: product.id,
								price: item.price,
							}));

							await props.addProductHiddenSpots(hiddenOnSpots);
							await props.addProductPriceOnSpots(priceOnSpots);
						}

						// updateProduct(dispatch, {
						// 	...product,
						// 	hiddenOnSpots,
						// 	priceOnSpots,
						// });

						// let data = { ...product, hiddenOnSpots, priceOnSpots };
						// updateProduct({
						// 	...product,
						// 	hiddenOnSpots,
						// 	priceOnSpots,
						// });
						// let data = { id: productId, product };

						// updateProduct(dispatch, { id: product.id, data });
						const products = await props.getProducts();
						console.log("products after create product", products);
						if (products) {
							updateProductsDispatch(dispatch, products);
							alert("Готово!");
							setPreloader(false);
							props.history.push(
								`/page/menu/products/edit/category${product.categoryId}`
							);
						}
					} else {
						alert("Ошибка, попробуйте ещё раз!");
						setPreloader(false);
					}

					// props.firebase.bd
					// 	.ref(
					// 		`${
					// 			JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					// 		}/products`
					// 	)
					// 	.push(product)
					// 	.then((res) => {
					// 		setPreloader(false);
					// 		const productId = res.path.pieces_[1];

					// 		let spots = [];

					// 		for (let key in product.spots) {
					// 			const spot = product.spots[key];

					// 			spots.push(spot);
					// 		}

					// 		product.spots = spots;

					// 		let data = { id: productId, product };

					// 		console.log("data -->>>", data);
					// 		updateProduct(dispatch, data);
					// 		alert("Готово!");
					// 		// props.history.push(`/page/menu/products/edit/category${product.menuCategoryId}`);
					// 	})
					// 	.catch((error) => {
					// 		setPreloader(false);
					// 		console.error(error);
					// 	});
				} else {
					alert("Ошибка, попробуйте ещё раз!");
					setPreloader(false);
				}
			})
			.catch((error) => {
				alert("Ошибка, попробуйте ещё раз!!!");
				console.error(error);
				// setPreloader(false);
			});
	};

	return (
		<>
			<ProductsForm
				onSubmit={onSubmit}
				handleChangeCitiesSpot={handleChangeCitiesSpot}
				handleIsOpenCities={handleIsOpenCities}
				isOpenCities={isOpenCities}
				cities={cities}
				categories={categories}
				defaultLanguage={defaultLanguage}
				languages={sortLanguages}
			/>
			{preloader ? <Preloader /> : null}
		</>
	);
};

export default withActions(withFirebase(withRouter(AddProduct)));
