const ADD_WORKING_HOURS = 'ADD_WORKING_HOURS';
const ADD_DATA_FORM = 'ADD-DATA-FORM';
const ADD_KEY_SHOP = 'ADD-KEY-SHOP';
const CLEAR_FORM = 'CLEAR-FROM';

let initialState = {
    dataForm: [],
    workingHours: [],
    keyShop: null,
}

const workingHoursReducer = (state = initialState, action) => {
    switch(action.type){
        case ADD_WORKING_HOURS: {
            return {
                ...state,
                workingHours: action.workingHours,
            };
        }
        case ADD_DATA_FORM: {
            return {
                ...state,
                dataForm: action.dataForm,
            };
        }
        case ADD_KEY_SHOP: {
            return {
                ...state,
                keyShop: action.keyShop
            }
        }
        case CLEAR_FORM: {
            return {
                dataForm: [],
                workingHours: [],
                keyShop: null,
            }
        }
        default:
           return state;
    }
}

export const addWorkingHours = (dispatch, workingHours) => dispatch({type: ADD_WORKING_HOURS, workingHours});
export const addDataForm = (dispatch, dataForm) => dispatch({type: ADD_DATA_FORM, dataForm});
export const addKeyShop = (dispatch, keyShop) => dispatch({type: ADD_KEY_SHOP, keyShop});
export const clearForm = (dispatch) => dispatch({type: CLEAR_FORM});

export default workingHoursReducer;