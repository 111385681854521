export const trim = (str, size) => str.length > size ? str.slice(0, size) + '…' : str;

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

export const formatPathname = (pathname) => {
    pathname = pathname.split('/');
    pathname[pathname.length - 1] = null;
    pathname.filter(item => !!item);
    return pathname = pathname.join('/');
}

export const updateFirebase = (firebase, url, data) => {
    let result;

    firebase.bd.ref(url).set(data)
        .then(res => {
            result = true;
        })
        .catch(error => {
            result = false;
            console.error(error);
        });

    return result;
}