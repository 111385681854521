import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {reduxForm} from 'redux-form';

import AddCategories from './AddCategories';
import AddProducts from './AddProducts';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';

import ModalComponent from '../../../components/ModalComponent';

const Products = ({ 
    loadStatus, 
    editShops, 
    noDiscountForCategorieIds, 
    noDiscountForProductIds, 
    // handleSubmit,
    saveData,
    ...props 
}) => {
    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    const submitForm = () => {
        setOpenModal(true);
        setIsLoading(true);
    }

    useEffect(() => {
        if (isLoading && isSubmit && !openModal) {
            saveData()

            console.log('Вы подтвердили');
            handleCloseModal()
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, isLoading, openModal]);


    return (
        <div className={classes.discountWrapper}>
            <div>
                <ModalComponent
                    title="Подтвердить действие!"
                    description="Вы подтверждаете сохранение категорий и товаров?" 
                    open={openModal} 
                    handleClose={handleCloseModal} 
                    handleClickSubmit={handleClickSubmit} />

                <AddCategories  loadStatus={loadStatus}
                                editShops={editShops}
                                noDiscountForCategorieIds={noDiscountForCategorieIds}
                                handleCategoriesPromocode={props.handleCategoriesPromocode} /> 

                <AddProducts    loadStatus={loadStatus}
                                editShops={editShops}
                                noDiscountForProductIds={noDiscountForProductIds}
                                handleProductsPromocode={props.handleProductsPromocode} /> 

                <div className={classes.buttonWrapper}>
                    <Button className={classes.button} text={loadStatus ? 
                        <Loading size={22} color={'#fff'}/> : 'Сохранить'} 
                                    disabled={loadStatus ? true : false}
                                    handleClick={() => submitForm()}/>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    discountWrapper: {
        
    },
    buttonWrapper: {
        marginTop: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        textTransform: 'capitalize'
    }
}))

export default reduxForm({
    form: 'Promcodes', 
  })(Products);
