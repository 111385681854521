import React from 'react';
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import s from './edit.module.css';

const listCategories = (props) => {
    return(
        <Router>
            <div className={s.listCategoriesWrapper}>
                <nav className={s.navigation}>
                    <ul className={s.listCategories}>
                        {props.categories.map((item, index) => {
                            let pathname = props.history.location.pathname;
                            let isCategory = pathname.indexOf('category');

                            if (isCategory !== -1) {
                                pathname = pathname.split('/');
                                pathname[pathname.length - 1] = null;
                                pathname.filter(item => !!item);
                                pathname = pathname.join('/');
                            }

                            let flag = isCategory !== -1 ? 'category' : '/category';
                            let url = pathname + flag + item.id;

                            return (
                                <>
                                   {!item.open && (
                                     <Link to={url} className={`${s.category} ${item.id === props.activeCategory ? s.activeCategory : ''}`} 
                                         key={index} onClick={() => props.updateCategory(item.id)}>
                                             {item.name}
                                     </Link>
                                 )}
                                </>
                             )
                         })}
                    </ul>
                </nav>
            </div>
        </Router>
    )
}

export default withRouter(listCategories);