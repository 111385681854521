import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../../firebase";

import Promocodes from "./index";

class PromocodesContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			promocodes: null,
			keyShop: null,
		};
	}

	componentDidMount() {
		this.setState({
			keyShop: this.props.match.url,
		});

		this.getFieldsSaleFirstOrder();
	}

	getFieldsSaleFirstOrder = () => {
		let url = this.props.match.url.split("/");
		url = url.find((item) => item === "0" || parseInt(item));
		console.log("url --- .>>", url);
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${url}/promocodes`
			)
			.on("value", (snapshot) => {
				let promocodes = snapshot.val();

				this.setState({ promocodes, keyShop: url });
			});
	};

	changeChecker = (value, id) => {
		console.log("id (changeChecker) --- >", id);
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.state.keyShop}/promocodes/${id}`
			)
			.update({ available: value })
			.then((res) => {
				this.setState({ preloader: false });
			});
	};

	deletePromocode = (id) => {
		console.log("=== Delete promocode ===", id);
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.state.keyShop}/promocodes/`
			)
			.child(id)
			.remove()
			.then((res) => {
				this.setState({ preloader: false });
			});
	};

	sendData = (data) => {
		console.log("=== Submit data (promocodes) ===", data);

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.state.keyShop}/promocodes/`
			)
			.set(data)
			.then((res) => {
				this.setState({ preloader: false });
				this.getFieldsSaleFirstOrder();
			});
	};

	render() {
		return (
			<>
				<Promocodes
					onSubmit={this.sendData}
					loadStatus={this.state.preloader}
					promocodes={this.state.promocodes}
					changeChecker={this.changeChecker}
					deletePromocode={this.deletePromocode}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(PromocodesContainer)));
