import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { withFirebase } from "../../../../firebase";
import { withActions } from "../../../../api";
import {
	updateCategories,
	updateProduct,
	editProduct,
	updateProductsDispatch,
	updateLanguages,
} from "../../../../reducers/shops";
import { withRouter } from "react-router-dom";

import EditProduct from "./editProduct";
import Loading from "../../../../components/Loading";

const EditProductContainer = (props) => {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth.login);
	// const cities = useSelector(state => state.shops.cities);
	const categories = useSelector((state) => state.shops.categories);
	// const products = useSelector((state) => state.shops.products);
	const defaultLanguage = useSelector((state) => state.shops.defaultLanguage);
	// const languages = useSelector(state => state.shops.languages);
	const cityId = props.match.params.id;

	const [preload, setPreload] = useState(false);
	const [products, setProducts] = useState(null);
	const [cities, setCities] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [product, setProduct] = useState(null);
	const [citiesSpot, setCitiesSpot] = useState([]);
	const [sortLanguages, setSortLanguages] = useState(null);
	const [deliveryTerminals, setDeliveryTerminals] = useState(null);
	const [deliveryTerminalsCheckBox, setDeliveryTerminalsCheckBox] =
		useState(null);
	const [isOpenCities, setIsOpenCities] = useState(true);

	const produtcsList = useSelector((state) => state.shops.products);
	const shops = useSelector((state) => state.shops.shops);

	useEffect(() => {
		getLanguages();
	}, []);

	useEffect(() => {
		if (languages) {
			sortedLanguages();
		}
	}, [languages]);

	useEffect(() => {
		if (!product && products) {
			getProduct();
		}

		if (!products) {
			getProducts();
		}
	}, [products]);

	useEffect(() => {
		getProducts();
	}, [produtcsList]);

	useEffect(() => {
		if (!cities) {
			getCities();
		}
	}, [cities]);

	useEffect(() => {
		if (shops) {
			getCities();
		}
	}, [shops]);

	const getProducts = () => {
		produtcsList && setProducts(produtcsList);
		console.log("products in get products function", produtcsList);

		// props.firebase.bd
		// 	.ref(
		// 		`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/products`
		// 	)
		// 	.once("value", (snapshot) => {
		// 		let products = snapshot.val();

		// 		if (products) setProducts(products);
		// 	});
	};

	const getProduct = () => {
		const isArray = Array.isArray(products);
		const newArray = Object.keys(products).map((key) => products[key]);
		const prod = (isArray ? products : newArray).find(
			(item) => item.id === +cityId
		);

		console.log("prod **** ", product);
		setProduct(prod);
		prod?.priceOnSpots.length === 0 &&
			// prod?.hiddenOnSpots.length === 0 &&
			setIsOpenCities(false);
		// for (let id in products) {
		// 	if (id === cityId) {
		// 		setProduct(products[cityId]);
		// 	}
		// }
	};

	const getLanguages = () => {
		props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/languages`
			)
			.once("value", (snapshot) => {
				let languages = snapshot.val();

				setLanguages(languages);
			});
	};

	const sortedLanguages = () => {
		languages.sort((a, b) => {
			if (a.key !== defaultLanguage) {
				return 1;
			} else {
				return -1;
			}
		});

		setSortLanguages(languages);
	};

	const getCities = () => {
		// props.firebase.bd
		// 	.ref(
		// 		`${
		// 			JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
		// 		}/settings/City`
		// 	)
		// 	.once("value", (snapshot) => {
		// 		let data = snapshot.val();

		// 		if (data) setCities(data);
		// 	});

		setCities(shops);
	};

	const handleDeliveryTerminalsCheckBox = (data) =>
		setDeliveryTerminalsCheckBox(data);
	const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
	const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

	const createProduct = (data) => {
		console.log("createProduct");

		let languagesName = {};
		let languagesDescription = {};

		for (let index in languages) {
			let key = languages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		let spots = [];
		let allTerminals = [];

		cities.map((city) => allTerminals.push(...city.deliveryTerminals));

		allTerminals.map((terminal, index) => {
			let spot = {
				price: "0",
				spot_id: terminal.id,
				visible: "0",
			};
			spots[index] = spot;
		});

		// console.log("cites", cities);
		console.log("products", products);
		console.log("spots ***** ", spots);

		for (let key in spots) {
			const terminal = spots[key];
			citiesSpot.map(({ id, visible }) => {
				if (String(terminal.spot_id) === String(id)) {
					let spot = {
						price:
							data.firstPrice && !isOpenCities
								? String(Number(data.firstPrice * 100))
								: String(Math.round(+data.terminals[id] * 100)),
						spot_id: id,
						visible:
							(cities.length === 1 && citiesSpot.length === 1) ||
							(data.firstPrice && !isOpenCities)
								? "1"
								: visible,
					};

					spots[key] = spot;
				}
			});
		}

		const hiddenSpots = spots
			.filter((item) => item.visible === "0")
			.map((item) => ({ spot_id: +item.spot_id }));

		const productPrices =
			product?.priceOnSpots.length > 0 ? product?.priceOnSpots : false;

		const priceOnSpots = spots.map((item) => ({
			price: +item.price ? +item.price : 0,
			spot_id: +item.spot_id,
			product_id: +cityId,
		}));

		const priceOnSpotsWithId =
			productPrices &&
			priceOnSpots.map((item1) => {
				const item2 = productPrices.find(
					(item2) => item2.spot_id === item1.spot_id
				);

				if (item2) {
					return {
						...item1,
						id: item2.id,
					};
				} else {
					return item1;
				}
			});

		// const pricesOnSpots = spots?.map((item) => {
		// 	const match = priceOnSpots.find(
		// 		(element) => element.spot_id === item.spot_id
		// 	);
		// 	return { ...item, ...match };

		// });

		const pricesOnSpots = productPrices ? priceOnSpotsWithId : priceOnSpots;

		setPreload(true);

		let prod = {
			id: cityId,
			categoryId: data.categories,
			name: languagesName,
			description: languagesDescription,
			price: String(Math.round(data.firstPrice * 100)),
			// price: data.firstPrice,
			// spots,
		};

		return { prod, pricesOnSpots, hiddenSpots };
	};

	const onSubmit = async (data) => {
		console.log("data --- ==>>> ", data);
		// let product = createProduct(data);
		const productData = createProduct(data);
		let product = productData.prod;
		const pricesOnSpots = productData.pricesOnSpots;
		const hiddenOnSpots = productData.hiddenSpots;

		if (data.photo) {
			let file = new FormData();
			file.append("photo", data.photo[0]);
			file.append("login", login);
			axios
				.post(`https://e-admin.com.ua/photo/index.php`, file)
				.then(async (res) => {
					const namePhoto = res.data.image_name.split(" ")[0];
					const isSuccess = res.data.check_upload_image;
					// console.log('res ---- >>> ', res);
					// console.log('product ---- >>> ', product);

					product.image = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;

					if (isSuccess) {
						const res = await props.updateProduct(product);
						let newPiceOnSpots;

						console.log("res after update", res);

						if (!!res) {
							// editProduct(dispatch, product);
							if (isOpenCities) {
								const pricesOnSpotsRes = await props.addProductPriceOnSpots(
									pricesOnSpots
								);
								console.log("prices updated !!!!", pricesOnSpotsRes);
								const hiddenRes = await props.updateHiddenSpotsProduct({
									id: product.id,
									data: hiddenOnSpots,
								});
								console.log("hidden updated !!!!", hiddenRes);

								newPiceOnSpots = pricesOnSpotsRes.map((item) => ({
									id: item.id,
									spot_id: item.spot_id,
									price: item.price,
									product_id: item.product_id,
								}));
							}
							const updatedPorducts = products.map((item) =>
								item.id === +product.id
									? {
											...product,
											id: +product.id,
											priceOnSpots: pricesOnSpots ? newPiceOnSpots : [],
											hiddenOnSpots: hiddenOnSpots.map((item) => ({
												...item,
												visible: false,
											})),
									  }
									: item
							);
							updateProductsDispatch(dispatch, updatedPorducts);
							setPreload(false);
							setTimeout(
								() =>
									props.history.push(
										`/page/menu/products/edit/category${data.categories}`
									),
								300
							);
						} else {
							alert("Ошибка, попробуйте ещё раз!");
							setPreload(false);
						}
					} else {
						alert("Ошибка, попробуйте ещё раз!");
						setPreload(false);
					}
				})
				.catch((res) => {
					alert("Ошибка, попробуйте ещё раз!");
					setPreload(false);
				});
		} else {
			const res = await props.updateProduct(product);
			let newPiceOnSpots;

			if (!!res) {
				if (isOpenCities) {
					const pricesOnSpotsRes = await props.addProductPriceOnSpots(
						pricesOnSpots
					);
					console.log("response prices updated !!!!", pricesOnSpotsRes);
					const hiddenRes = await props.updateHiddenSpotsProduct({
						id: product.id,
						data: hiddenOnSpots,
					});
					console.log("hidden updated !!!!", hiddenRes);

					newPiceOnSpots = pricesOnSpotsRes.map((item) => ({
						id: item.id,
						spot_id: item.spot_id,
						price: item.price,
						product_id: item.product_id,
					}));
				}

				const updatedPorducts = products.map((item) =>
					item.id === +product.id
						? {
								...product,
								id: +product.id,
								priceOnSpots: pricesOnSpots ? newPiceOnSpots : [],
								hiddenOnSpots: hiddenOnSpots.map((item) => ({
									...item,
									visible: false,
								})),
						  }
						: item
				);
				updateProductsDispatch(dispatch, updatedPorducts);
				setPreload(false);
				setTimeout(
					() =>
						props.history.push(
							`/page/menu/products/edit/category${data.categories}`
						),
					300
				);
			} else {
				alert("Ошибка, попробуйте ещё раз!");
				setPreload(false);
			}
		}

		// отправка логов в телеграм
		await props.sendToTelegram({
			message: `<b>Osama sushi</b>: в адмін-панелі був відредагований товар ${data.name_uk}`,
			token: "6390810224:AAGJXwGrIHR5bpN41W9sKycdkylDI3HhqrQ",
			chatId: "-954592866",
		});
	};

	return product ? (
		<EditProduct
			product={product}
			onSubmit={onSubmit}
			handleCheckBox={handleDeliveryTerminalsCheckBox}
			// deliveryTerminals={this.state.deliveryTerminals}
			handleChangeCitiesSpot={handleChangeCitiesSpot}
			handleIsOpenCities={handleIsOpenCities}
			isOpenCities={isOpenCities}
			cityId={cityId}
			cities={cities}
			languages={sortLanguages}
			defaultLanguage={defaultLanguage}
			categories={categories}
			products={products}
		/>
	) : (
		<Loading />
	);
};

export default withActions(withFirebase(withRouter(EditProductContainer)));
