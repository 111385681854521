import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const Switches = ({ id, className, active, ...props }) => {
    const classes = useStyles();
    
  const [state, setState] = useState({
    checkedB: active,
  });

  useEffect(() => {
    if (state.checkedB !== active) {
      setState({ checkedB: active })
    }
  }, [active]);

  const handleChange = (event) => {
    const checked = event.target.checked;
    const {changeChecker} = props;

    if (id || id === 0) {
      changeChecker(checked, id);
    } else {
      changeChecker(checked);
    }

    setState({
        [event.target.name]: checked }
    );
  };

  return (
    <div className={className}>
      <Switch
        disabled={props.disabled}
        className={classes.switch}
        checked={state.checkedB}
        onChange={handleChange}
        color="primary"
        name="checkedB"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
    switch: {
        // color: 'red'
    }
}));

export default Switches;