import React from 'react';
import LoginSceen from '../../components/LoginSceen/login';
import LoginForm from './loginForm';

const Login = (props) => {
    return(
       <LoginSceen>
            <LoginForm onSubmit={props.onSubmit} loadStatus={props.loadStatus}/>
       </LoginSceen>
    )
}

export default Login;