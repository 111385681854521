import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import TableClientList from './TableClientList';

const ClientList = () => {
    const classes = useStyles();
    const isMin600 = useMediaQuery('(max-width: 759px)');
    const isMax600 = useMediaQuery('(min-width: 760px)');
    return (
        <div>
            <div className={classes.tabWrapper}>
                {isMin600 && <Typography className={classes.titleMobile} variant="h1">Список клиентов</Typography>}
                <div className={classes.tabContent}>
                    <div className={isMax600 ? classes.desctopWrapper : classes.mobileWrapper}>
                        {isMax600 && <Typography className={classes.title} variant="h1">Список клиентов</Typography>}
                        <TableClientList />
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    tabWrapper: {
    
    },
    desctopWrapper: {
        padding: '24px 16px 24px 16px',
        background: 'white',
        border:' 0.5px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: '5px 5px 0px 0px',
    },
    tabContent: {
        [theme.breakpoints.down("760")]: {
            padding: '16px 8px',
            background: 'white',
            border: '1px solid #E5E5E5',
            borderRadius: 10,
        },
    },
    title: {
        marginBottom: 16,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    },
    titleMobile: {
        marginTop: 16,
        marginBottom: 8,
        fontSize: 22,
        fontWeight: 'bold',
        lineHeight: '29px',
    }
}));

export default ClientList;