import React from 'react';
// import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addDataForm } from '../../reducers/workingHours';

import { makeStyles } from '@material-ui/core/styles'
import { createTextMask } from 'redux-form-input-masks';
import { Field } from 'redux-form';

const renderField = ({ 
    input, 
    meta, 
    ...rest 
  }) => {
    const {data, dataForm, dispatchToRedux, addDataForm} = rest;
    const inputVal = input.value ? input.value.replace(/:/g, '').replace(/-/g, '').replace(/_/g, '') : null;
    const initVal = meta.initial ? meta.initial : null;
    const value = input.value.replace(':', '');
    const inputSelector = inputVal && inputVal.length === 4 && initVal && initVal.length === 4;

    if (inputSelector) {
       if (+inputVal !== + initVal) {

            for (let day in dataForm) {
                const item = dataForm[day];

                // console.log('item ***', item);
                // console.log('data**', data);

                if (item.dayNumber === data.dayNumber) {
                    let deliveryEnd = inputVal.split('');
                    deliveryEnd = deliveryEnd[0] + deliveryEnd[1] + ':' + deliveryEnd[2] + deliveryEnd[3];

                    console.log('deliveryEnd --->>>', deliveryEnd);

                    let newDataForm = {
                        ...dataForm,
                        [day]: {
                            ...data,
                            deliveryEnd: deliveryEnd,
                        }
                   };
                //    console.log('newDataForm NNN --->>>', newDataForm);
                   addDataForm(dispatchToRedux, newDataForm);
                }
            }
       }
    }
    
    return (
      <div>
          <input
            value={input.value}
            onChange={input.onChange} 
            {...rest} />
            {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    )
}

const myCustomMaskDefinitions = {
    5: {
        regExp: /[0-5]/,
      },
    9: {
      regExp: /[0-9]/,
    },
  };

const InputDeliveryEndWorkingTime = ({ 
    data, 
    // dataForm, 
    // setDataForm, 
    name,
    orderTimeIntervalDelivery,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const dataForm = useSelector(state => state.workingHours.dataForm);
    const isWeekendTime = data.checkNumber === 3;
    const phoneMask = createTextMask({ 
        pattern: '59:59',
        maskDefinitions: myCustomMaskDefinitions, 
    });
    if (!isWeekendTime) {
        // console.log('dataForm -->>>', dataForm);
        return (
            <div className={classes.calendarWrapper} >
                <Field 
                    disabled={data.checkbox}
                    name={name}
                    data={data}
                    component={renderField}
                    className={classes.calendarField} 
                    dataForm={dataForm}
                    dispatchToRedux={dispatch}
                    addDataForm={addDataForm}
                    orderTimeIntervalDelivery={orderTimeIntervalDelivery}
                    {...phoneMask} 
                />
            </div>
        )
    } else {
        return null;
    }
}

const useStyles = makeStyles({
    calendarWrapper: {

    },
    calendarField: {
        width: 140,
        textAlign: 'center',
        height: 30,
        fontSize: 16,
        lineHeight: '30px',
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        overflow: 'hidden',
        '& .MuiInput-input': {
            textAlign: 'center',
            padding: '5px 0 7px',
            color: '#476282',
        },
        '& .MuiInput-input:disabled': {
            background: '#f5f5f5',
            border: '1px solid transparent',
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        },
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none'
        },
    },
});

export default InputDeliveryEndWorkingTime;