import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import FormEditCategorie from '../editCategory';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { EyeIcon, EyeOffIcon } from './icons';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import * as clipboard from "clipboard-polyfill/text";
import Popover from '../../../../components/Popover';
import useStyles from './styles';

const ListCategory = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [active, setActive] = useState(false);
    const [names, setNames] = useState('');
    const [id, setId] = useState('');
    const [activeCategorie, setActiveCategorie] = useState(null);
    const [isActiveAlert, setIsActiveAlert] = useState(false);
    const [categoriesState, setCategoriesState] = useState([]);
    const [boxState, setBoxState] = useState(null);

    const [categoriesDone, setCategoriesDone] = useState(null);
    const [hiddenCategoriesDone, setHiddenCategoriesDone] = useState(null);
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    const handleOpen = () => {
        setActive(!active);
    }


    const handleClose = () => {
        console.log('--- close ---');
        setActive(false);
        setBoxState(null);
    }

    const copyText = (id) => {
        console.log('id', id)
        clipboard.writeText(id)
            .then(() => setIsActiveAlert(id))
            .catch(() => console.log('Error write text!'));
            setTimeout(() => setIsActiveAlert(false), 2000);
    }

    const handleClickOpen = (names, id) => {
        console.log(' --- edit ---');
        setBoxState(null);
        handleOpen();
        setNames(names);
        setId(id);
        setActiveCategorie(props.languages);
    } 

    const handleOffCategorie = (id) => {
        let clickItem = categoriesState.find((item) => item.id === id);

        // if (!clickItem.open) {
        //     clickItem.open = true;
        // } else {
        //     clickItem.open = false;
        // }
        if (clickItem.isHidden) {
            clickItem.isHidden = false;
        } else {
            clickItem.isHidden = true;         
        }

        // setCategoriesState([
        //     ...categoriesState,
        // ]);

        const hiddenSpots = clickItem.hiddenOnSpots.map(item => ({spot_id: +item.spot_id}))
        const visibleSpots = clickItem.spots.map(item => ({spot_id: +item.spot_id}))

        const allSpots = [...hiddenSpots, ...visibleSpots]

        // let data = props.defaultHiddenCategories ? props.defaultHiddenCategories : [];

        // // if (!data.includes(id)) {
        // //     // console.log(' --- 1 ----');
        // //     data.push(id);
        // // } else {
        // //     // console.log(' --- 2 ----');
        // //     data = data.filter(item => item !== id); 
        // // }

        // console.log('1 --- >>> ', data, id);

        props.hiddenCategory(clickItem.id, allSpots, clickItem.isHidden);
    }
    
    useEffect(() => {
        const {categories, hiddenCategories} = props;
        if (categories) setCategoriesDone(categories);
        if (hiddenCategories) setHiddenCategoriesDone(hiddenCategories);
    }, [props.categories, props.hiddenCategories])

    let filtredCategories = [];

    if (categoriesDone || hiddenCategoriesDone) {
        filtredCategories = [...categoriesDone];

        if (props.categories && props.hiddenCategories) {
            for (let key = 0; key < categoriesDone.length; key++) {
                 let item = categoriesDone[key];
 
                 for (let catId in hiddenCategoriesDone) {
                     let hiddenItem = hiddenCategoriesDone[catId];
 
                     if (item.id === hiddenItem) {
                         filtredCategories[key] = {
                             ...filtredCategories[key],
                             open: true,
                         }
                     }
                 }
            }
        }

        if (categoriesState.length !== filtredCategories.length) {
           setCategoriesState(filtredCategories);
        }

        categoriesState.map((item, index) => {
            if (filtredCategories && filtredCategories[index]) {
               let filtredName = filtredCategories[index].name;
               for (let key in item.name) {
                  let name = item.name;

                  if (filtredName[key] !== name[key]) {
                      setCategoriesState(filtredCategories);
                  }
               }
            }
        })
    }

    return(
        <div className={classes.listContainer}>
            <div 
                id={id} 
                names={names}
                activeCategories={activeCategorie}
                deliveryTerminals={props.deliveryTerminals}
                handleCheckBox={props.handleCheckBox}
                languages={props.languages}
                categories={props.categories}
                defaultLanguage={props.defaultLanguage}
                setCategoriesDone={setCategoriesDone}
                categoriesDone={categoriesDone}
                open={active} 
                onSubmit={props.editCategory} 
                handleClose={handleClose}
                boxState={boxState}
                setBoxState={setBoxState} />
            {categoriesState.map((item, index) => {
                let language = props.language;
                return(
                    <div className={classes.item} key={index}>
                        <span className={classes.itemWrap}>
                            {item.name[language] ? item.name[language] : item.name[props.defaultLanguage]}
                            </span>
                        <div className={classes.wrapper}>
                            <div className={classes.copyCategoryWrapper}>
                                <div 
                                    className={classes.copyCategory}
                                    title={`Скопировать ID категории ${item.id}`}
                                    onClick={() => copyText(item.id)} 
                                >
                                    <div className={`${classes.copyLinkIcon} ${isActiveAlert === item.id && classes.copyLinkIconActive}`}><LinkIcon /> </div>
                                    {matches && <div className={`${classes.copyLinkText} ${isActiveAlert === item.id && classes.copyLinkTextActive}`}>{isActiveAlert === item.id ? 'Скопировано': 'Копировать'}</div>}
                                </div>
                            </div>
                            <div className={classes.eye}>
                                <span onClick={()=>handleOffCategorie(item.id, index)}>
                                    {!item.isHidden && (
                                        <EyeIcon style={{
                                            fill: 'black',
                                            cursor: 'pointer',
                                        }} />
                                    )}
                                    {item.isHidden && (
                                        <EyeOffIcon style={{
                                            fill: 'black',
                                            cursor: 'pointer',
                                        }} />
                                    )}
                                    {/* {!item.open && (
                                        <EyeIcon style={{
                                            fill: 'black',
                                            cursor: 'pointer',
                                        }} />
                                    )}
                                    {item.open && (
                                        <EyeOffIcon style={{
                                            fill: 'black',
                                            cursor: 'pointer',
                                        }} />
                                    )} */}
                                </span>
                            </div>
                            <div className={classes.edit}>
                                <Link
                                    className={classes.editLink} 
                                    to={`${props.match.path}/editCategory/${item.id}`}
                                >
                                    <EditIcon className={classes.editIcon} />
                                </Link>
                            </div>
                            <div className={classes.edit}>
                                <span onClick={()=>props.deleteCategory(item.id, item.name[props.defaultLanguage])}><DeleteIcon /></span>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default withRouter(ListCategory);