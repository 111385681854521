import React from 'react';
import Firebase, { FirebaseContext } from './firebase';

import MainRouter from './MainRouter';

const RenderFirebase = (props) => {
    return(
      <FirebaseContext.Provider value={new Firebase({config: props.config})}>
          <MainRouter/>
      </FirebaseContext.Provider>
    );
}

export default RenderFirebase;