import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import FirstOrder from './discounts/firstOrder';
import Pickup from './discounts/pickupOrder';
import Delivery from './discounts/deliveryOrder';

const Discounts = ({ saleFirstOrders, changeChecker, onSubmitFirstOrder, pickupAndDeliveryDiscounts }) => {
    const classes = useStyles();

    return (
        <div className={classes.discounts}>
            <Typography className={classes.typography} variant="h5">Скидки</Typography>
            <FirstOrder 
                saleFirstOrders={saleFirstOrders} 
                changeChecker={changeChecker} 
                onSubmit={onSubmitFirstOrder} />
                
            {pickupAndDeliveryDiscounts && (
                <>
                    <Pickup />
                    <Delivery />
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    discounts: {
        marginTop: 30,
        padding: 16,
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('600')]: {
            marginTop: 0,
            background: 'white',
        },
    },
    typography: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
    }
}))

export default Discounts;