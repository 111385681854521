import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { checkedTableInput } from '../../../../reducers/clientTable';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TableList = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const table = useSelector(state => state.clientTable.table);
	let allClients = useSelector(state => state.clientTable.allClients);
	const handleChange = (phone) => {
		const { checked } = allClients.find(t => t.phone === phone);

		let checkData = {
			phone,
			checked: !checked
		};

		dispatch(checkedTableInput(checkData))
		// console.log('checkData -->>>', checkData);
	}
	return (
		<div className={classes.table}>
			{table.length === 0 && <div className={classes.noResults}>Нет результатов</div>}
			{table && allClients && table.map((column, index) => (
				<div key={index} className={`${classes.column} ${column.id === 'ids' ? 'ids': column.id === 'gender' ? 'gender' : column.id === 'discount' ? 'discount' : column.id === 'platform' ? 'platform' : '' }`}>
					{column.content.map((el, key) => {
						const phone = table.find(t => t.id === 'phone').content[key];
						const item = allClients.find(t => Number(t.phone) === Number(phone));

						return (
							<div key={key} classname={classes.tableRow}>	
								{column.id === 'ids' ? <span>
									<FormControlLabel
										control={
										<Checkbox
											checked={item ? item.checked : false}
											onChange={() => handleChange(phone)}
											name={phone}
											color="primary"
										/>
										}
										label=""
									/>
								</span>: el}
							</div>
						)
					})}
				</div>
			))}
		</div>
	)
}

const useStyles = makeStyles(theme => ({
    table: {
		display: 'flex',
		marginTop: 15,
        width: '100%',
        height: 350,
        overflowY: 'auto',
		background: '#f2f2f2;'
	},
	column: {
		'&.ids > div': {
			width: 75,
		},

		'&.gender > div': {
			width: 75,
		},

		'&.discount > div': {
			width: 100,	
		},

		'&.platform > div': {
			width: 140,
		},
		
		'& > div': {
			width: 200,
			paddingLeft: 30,
			// paddingRight: 15,
			lineHeight: '60px',
			backgroundColor: '#f2f2f2',
			borderBottom: '1px solid #ccc',	
			boxSizing: 'border-box',
			whiteSpace: 'nowrap',
			overflowX: 'hidden',
			'&:last-child': {
				borderBottom: 'none',
			}
		},
	},
	tableRow: {
		// color: 'red',
	},
	noResults: {
		textAlign: 'center',
		margin: '0 auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	}
}));

export default TableList;