/* eslint-disable array-callback-return */
import React, { Component } from "react";

import Promotion from "./promotion";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../firebase";
import axios from "axios";

class PayCardContainer extends Component {
	constructor() {
		super();

		this.state = {
			loadStatus: false,
			webcode: "",
			universalLink: "",
		};
	}

	componentDidMount() {
		this.getPromotion();
		// this.getQRCode();
	}

	getPromotion = () => {
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		this.props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/adminPermission`)
			.on("value", (snapshot) => {
				let val = snapshot.val();

				if (val) {
					if (!this.state.webcode) {
						this.setState({
							webcode: val.promotion.webcode,
							universalLink: val.promotion.universalLink,
						});
					}
				}
			});
	};

	// getQRCode = () => {
	//     axios.get(`http://qrcodes.com.ua/api/?u=${this.props.appSettings.branchLink}`, {headers: {
	//         'Access-Control-Allow-Origin': '*',
	//     }})
	//         .then((res) => console.log('res', res))
	//         .catch((err) => console.log('err', err));
	// }

	sendData = (data) => {
		// console.log('data');
	};

	render() {
		let branchLink = this.props.appSettings.branchLink;
		return (
			<>
				<Promotion
					webcode={this.state.webcode}
					universalLink={this.state.universalLink}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		appSettings: state.shops.appSettings,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(PayCardContainer)));
