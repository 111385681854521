import React, { Component } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import {connect} from 'react-redux';
import {updateFirebaseConfig} from '../../reducers/auth';
import PersonIcon from '@material-ui/icons/Person';
import {withRouter} from 'react-router-dom';

const styles = theme => ({
    iconButton: {
        '& svg': {
            fontSize: 30
        }
    },
    login: {
        fontSize: 18,
        color: '#326dff',
        fontWeight: 500,
        lineHeight: 1.8,
        paddingLeft: 15,
        paddingRight: 30,
        textTransform: 'uppercase'
    },
    avatar: {
        color: '#fff',
        background: '#326dff',
        fontSize: 15,
        fontWeight: 500
    },
    logOut: {
        fontSize: 16,
        textAlign: 'center',
        color: '#F44336',
        fontWeight: 500,
    },
    menu: {
        minWidth: 200
    }
  });

class Profile extends Component{

    state = {
        anchorEl: undefined,
        open: false
      };

      handleOpenProfile = event => {
        this.setState({ open: true, anchorEl: event.currentTarget });
      };
    
      handleRequestClose = () => {
        this.setState({ open: false });
      };

      logOut = () => {  
        this.props.signOut();
        localStorage.clear('@login');
        this.props.updateFirebaseConfig(null);
        this.handleRequestClose();
        this.props.history.push('/');
      }

    render(){
        const { classes } = this.props;
        const loginName = localStorage.getItem('@login');

        return(
            <>
            <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleOpenProfile}
                size={'medium'}
                className={classes.iconButton}
              >
                <Avatar className={classes.avatar}>
                    <PersonIcon style={{ fontSize: 22 }}/>
                </Avatar>
              </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={this.state.open}
                onClose={this.handleRequestClose}
                className={classes.menu}
                width={300}
              >
                <span className={classes.login}>{loginName}</span>
                <MenuItem className={classes.logOut} onClick={() => this.logOut()}>Выйти</MenuItem>
              </Menu>
              </>
        )
    }
}

export default withStyles(styles)(connect('', {updateFirebaseConfig})(withRouter(Profile)));