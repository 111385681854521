import * as axios from "axios";

const instance = axios.create({
	baseURL: "https://e-api.com.ua/api",
	// baseURL: "https://e-api.ithinkers.fun/api",
	// baseURL: 'https://e-admin.com.ua/api/api.php',
});

// export const firebaseAPI = {
//     getConfig(login){
//         // eslint-disable-next-line new-parens
//         var body = new FormData;
//         body.append('login', login);
//         return instance.post(``, body)
//         .then (response => {
//             return response.data;
//         })
//     },
// };

export const firebaseAPI = {
	getConfig(login) {
		return instance
			.get(`/v1/client/firebaseConfig/${login}`)
			.then((response) => {
				const config = response.data.config;
				const isValidConfig = Object.keys(config).length === 0;

				if (isValidConfig) {
					throw new Error("Файл конфигурации пустой.");
				}

				return response.data.config;
			});
	},
};
