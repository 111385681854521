import React from 'react';
import load from '../img/load.gif';
import s from './preloader.module.css';

const Prelodaer = (props) => {
    return(
        <div className={s.container}>
            <img src={load}/>
        </div>
    )
}

export default Prelodaer;