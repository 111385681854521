import React from 'react';
import { makeStyles } from '@material-ui/core/styles'


const TabMobile = (props) => {
    const classes = useStyles();
    const { data, active, handleTabs } = props;

    const Tab = (props) => 
      <div 
        style={{
          background: props.active ? 'white': '',
          color: props.active ? '#222': '',
        }} 
        id={props.id} 
        className={classes.tab} 
        onClick={(e) => props.handleTabs(e)}>
        {props.name}
      </div>;

    return (
        <div className={classes.menu}>
          {data && data.map((item, key) => 
            <Tab 
              key={key}
              id={key} 
              name={item} 
              active={active === key} 
              handleTabs={handleTabs} /> )}          
        </div>
    )
}

const useStyles = makeStyles({
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '47px',
    padding: '0 4px',
    background: '#f2f2f2',
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.04)',
    borderRadius: '47px',
  },
  tab: {
    fontSize: 16,
    fontWeight: 500,
    width: '50%',
    height: 39,
    textAlign: 'center',
    lineHeight: '39px',
    borderRadius: '39px',
    color: '#6C6C6C',
  }
});

export default TabMobile;