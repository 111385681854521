import React from 'react';
import 'emoji-mart/css/emoji-mart.css'
import PushForm from './pushForm';
import Grid from '@material-ui/core/Grid';

const Push = (props) => {
    return(
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PushForm onSubmit={props.onSubmit}  loadStatus={props.loadStatus} {...props} />
            </Grid>
        </Grid>
    )
}

export default Push;