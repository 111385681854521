import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../firebase";

import Discount from "./index";

class PayCardContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			saleFirstOrders: null,
			saleFirstOrder: null,
			promoCodes: null,
			keyShop: null,
			adminPermission: {},
		};
	}

	componentDidMount() {
		this.setState({
			keyShop: this.props.match.params.city,
		});

		this.getFieldsSaleFirstOrder();
		this.getFieldSaleFirstOrder();
		this.getFieldsPromoCodes();
		this.getAdminPermission();
	}

	componentDidUpdate(prevProps, prevState) {
		let cityId = window.location.pathname.split("/");
		cityId = cityId[cityId.length - 1];
		if (this.state.keyShop !== cityId) {
			// console.log('prevProps, prevState', this.state.keyShop, cityId);
			this.setState({ keyShop: cityId });
			this.getFieldsSaleFirstOrder();
		}
	}

	getAdminPermission = () => {
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission`
			)
			.on("value", (snapshot) => {
				let adminPermission = snapshot.val();

				if (adminPermission) this.setState({ adminPermission });
			});
	};

	getFieldsSaleFirstOrder = () => {
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.props.match.params.city}/saleFirstOrder`
			)
			.on("value", (snapshot) => {
				let saleFirstOrders = snapshot.val();

				saleFirstOrders = saleFirstOrders ? saleFirstOrders : [];

				this.setState({ saleFirstOrders });
			});
	};

	getFieldSaleFirstOrder = () => {
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission/saleFirstOrder`
			)
			.on("value", (snapshot) => {
				let saleFirstOrder = snapshot.val();

				this.setState({ saleFirstOrder });
			});
	};

	getFieldsPromoCodes = () => {
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission/promoCodes`
			)
			.on("value", (snapshot) => {
				let promoCodes = snapshot.val();

				this.setState({ promoCodes });
			});
	};

	changeChecker = (value) => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/${this.props.match.params.city}/saleFirstOrder`
			)
			.update({ available: value })
			.then((res) => {
				this.setState({ preloader: false });
			});
	};

	sendData = (data) => {
		console.log("=== Submit data ===", data);

		let result = {
			available: true,
			discountId: "",
			id: "",
		};

		if (data) {
			result = { ...result, ...data };
		}

		console.log("result -->>>", result);

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}settings/City/${this.props.match.params.city}/saleFirstOrder`
			)
			.update(result)
			.then((res) => {
				this.setState({ preloader: false });
			});
		this.getFieldsSaleFirstOrder();
		this.props.getDefaultCity();
	};

	render() {
		// console.log('state, saleFirstOrder', this.state);
		return (
			<>
				<Discount
					onSubmitFirstOrder={this.sendData}
					adminPermission={this.state.adminPermission}
					loadStatus={this.state.preloader}
					keyShop={this.state.keyShop}
					saleFirstOrders={this.state.saleFirstOrders}
					saleFirstOrder={this.state.saleFirstOrder}
					promoCodes={this.state.promoCodes}
					changeChecker={this.changeChecker}
					handleCategoriesPromocode={this.props.handleCategoriesPromocode}
					handleProductsPromocode={this.props.handleProductsPromocode}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(PayCardContainer)));
