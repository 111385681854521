import { combineReducers, createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { composeWithDevTools } from "redux-devtools-extension";

import auth from "./auth";
import shops from "./shops";
import workingHours from "./workingHours";
import clientTable from "./clientTable";

let reducer = combineReducers({
	form: formReducer,
	auth,
	shops,
	workingHours,
	clientTable,
});

const store = createStore(
	reducer,
	composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export default store;
