import React from 'react';

export const MenuBurger = () => (
    <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1" fill="white">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H24C24.5523 0 25 0.447715 25 1C25 1.55228 24.5523 2 24 2H1C0.447716 2 0 1.55228 0 1ZM0 8C0 7.44772 0.447715 7 1 7H24C24.5523 7 25 7.44772 25 8C25 8.55229 24.5523 9 24 9H1C0.447716 9 0 8.55229 0 8ZM1 14C0.447715 14 0 14.4477 0 15C0 15.5523 0.447716 16 1 16H24C24.5523 16 25 15.5523 25 15C25 14.4477 24.5523 14 24 14H1Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H24C24.5523 0 25 0.447715 25 1C25 1.55228 24.5523 2 24 2H1C0.447716 2 0 1.55228 0 1ZM0 8C0 7.44772 0.447715 7 1 7H24C24.5523 7 25 7.44772 25 8C25 8.55229 24.5523 9 24 9H1C0.447716 9 0 8.55229 0 8ZM1 14C0.447715 14 0 14.4477 0 15C0 15.5523 0.447716 16 1 16H24C24.5523 16 25 15.5523 25 15C25 14.4477 24.5523 14 24 14H1Z" fill="#1D1B1B"/>
        <path d="M1 -1C-0.104569 -1 -1 -0.104569 -1 1H1V-1ZM24 -1H1V1H24V-1ZM26 1C26 -0.10457 25.1046 -1 24 -1V1H26ZM24 3C25.1046 3 26 2.10457 26 1H24V3ZM1 3H24V1H1V3ZM-1 1C-1 2.10457 -0.104568 3 1 3V1H1H-1ZM1 6C-0.10457 6 -1 6.89543 -1 8H1V6ZM24 6H1V8H24V6ZM26 8C26 6.89543 25.1046 6 24 6V8H26ZM24 10C25.1046 10 26 9.10457 26 8H24V10ZM1 10H24V8H1V10ZM-1 8C-1 9.10457 -0.104568 10 1 10V8H1H-1ZM1 15V13C-0.104569 13 -1 13.8954 -1 15H1ZM1 15H1H-1C-1 16.1046 -0.104568 17 1 17V15ZM24 15H1V17H24V15ZM24 15V17C25.1046 17 26 16.1046 26 15H24ZM24 15H26C26 13.8954 25.1046 13 24 13V15ZM1 15H24V13H1V15Z" fill="#326DFF" mask="url(#path-1-inside-1)"/>
    </svg>
);
  
export const MenuBurgerClose = () => (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.097631 0.683417 -0.0976307 1.31658 0.292894 1.70711L7.71752 9.13173L0.292893 16.5564C-0.0976311 16.9469 -0.0976311 17.58 0.292893 17.9706C0.683417 18.3611 1.31658 18.3611 1.70711 17.9706L9.13173 10.5459L16.5563 17.9706C16.9469 18.3611 17.58 18.3611 17.9706 17.9706C18.3611 17.58 18.3611 16.9469 17.9706 16.5563L10.5459 9.13173L17.9706 1.70711C18.3611 1.31659 18.3611 0.683422 17.9706 0.292897C17.58 -0.097627 16.9469 -0.0976266 16.5563 0.292897L9.13173 7.71752L1.70711 0.292893Z" fill="#326DFF"/>
    </svg>
);